// src/components/PdfViewer.js

import React from 'react';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import { highlightPlugin } from '@react-pdf-viewer/highlight';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';

// Import the styles for the PDF viewer
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';
import '@react-pdf-viewer/highlight/lib/styles/index.css';

const pdfjsVersion = '3.6.172';

const PdfViewer = ({ url, pageNumber, x1, y1, x2, y2 }) => {
  const highlightPluginInstance = highlightPlugin();

  const highlightArea = {
    height: `${Math.abs(y2 - y1)}px`,
    left: `${Math.min(x1, x2)}px`,
    top: `${Math.min(y1, y2)}px`,
    width: `${Math.abs(x2 - x1)}px`,
  };

  return (
    <div style={{ position: 'relative', width: '100%', height: '100%' }}>
      {/* Use Worker to handle the PDF.js Worker script path */}
      <Worker workerUrl={`https://unpkg.com/pdfjs-dist@${pdfjsVersion}/build/pdf.worker.min.js`}>
        <Viewer
          fileUrl={url}
          plugins={[
            highlightPluginInstance,
            defaultLayoutPlugin(),
          ]}
          initialPage={pageNumber - 1}
        />
      </Worker>
      <div
        style={{
          position: 'absolute',
          backgroundColor: 'rgba(255, 255, 0, 0.5)',
          border: '1px solid yellow',
          ...highlightArea,
        }}
      />
    </div>
  );
};

export default PdfViewer;
