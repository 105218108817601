import { createTheme } from '@mui/material/styles';

const KETheme = createTheme({
  shape: {
    borderRadius: 0,  // Sets borderRadius to 0 for all components
  },
  palette: {
    ke: {
      dark_blue: '#34495e',  // prior: #003767
      medium_blue: '#217dbb', // prior: #006892
      light_blue: '#87B2D8', // prior: #87B2D8
      medium_grey: '#7b818a',
      background: '#ecf0f1', // prior: #f2f3f3
      card_header: '#d7dadb', // prior: #eaeded
      link_hover: '#2596D1', // aka "curious_blue" prior: #67b2f5
      divider_unselected: '#cfd3d4',
      divider_selected: 'gray',
      search_border_unselected: '#cfd3d4',
      star: '#67b2f5',
    },
    // ... other palette settings
  },
  // ... other theme settings
});

export default KETheme;