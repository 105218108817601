import React, { useEffect, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { listDynKeTranscriptPreparedRemarksBlocks } from "../../../graphql_ap/queries";
import './PreparedRemarksBlocks.css'
import PreparedRemarksBlock from './PreparedRemarksBlock.js'

const PreparedRemarksBlocks = (props) => {
    const [preparedRemarksBlocksTable, setPreparedRemarksBlocksTable] = useState([]);

    useEffect(() => {
        async function fetchData() {
          if (props.ke_transcript_id) {
            try {
              const preparedRemarksBlocksData = await API.graphql(
                graphqlOperation(listDynKeTranscriptPreparedRemarksBlocks, {
                    ke_transcript_id: props.ke_transcript_id, 
                })
              );
              const preparedRemarksBlocksDataList = preparedRemarksBlocksData.data.listDynKeTranscriptPreparedRemarksBlocks;
    
              setPreparedRemarksBlocksTable(preparedRemarksBlocksDataList.map(item => ({
                ...item,
                id: item.prepared_remarks_block_num 
              })));
    
            } catch (error) {
              console.error("Error fetching prepared remarks blocks table:", error);
            }
          }
        }
    
        fetchData();
      }, [props.ke_transcript_id]);

    if (preparedRemarksBlocksTable.length === 0) {
        return <div></div>;
      }

    return (
        <div className = "prepared-remarks-section" style={{ overflowY: 'auto' }}>
            <div><b>PREPARED REMARKS:</b></div>
            <br></br>
            {preparedRemarksBlocksTable
              .sort((a, b) => a.id - b.id)
              .map((block, index) => (
                <div key={index}>
                    <PreparedRemarksBlock 
                        prepared_remarks_id = {block.prepared_remarks_block_id}
                        speaker_name = {block.speaker_name}
                        speaker_title = {block.speaker_title}
                        onUtteranceNumListSelect={props.onUtteranceNumListSelect}
                        onSelectItem={props.onSelectItem} 
                        selectedItemId={props.selectedItemId}
                    />
                </div>
            ))}
        </div>
    );
};

export default PreparedRemarksBlocks;