import React, { useState } from 'react';
import { Box } from '@mui/material'; 
import GuidanceTable from './GuidanceTable';
import PreparedRemarksBlocks from './PreparedRemarksBlocks';
import AISummarySection from './AISummarySection';
import QuestionAnswerBlocks from './QuestionAnswerBlocks';

const TranscriptsSummaryPane = (props) => {
	
  return (
    <Box className="card-full-height-main">
	  <Box className="card-title-bar" >
        <h1 className="card-main-title">Analysis</h1>
		<p className="card-sub-title">{props.selectedTicker} - {props.selectedCallTitle}</p>
      </Box>
	  <div className="card-full-height-body">
		<AISummarySection 
			AISummary={props.selectedAISummary}
			/>
		<GuidanceTable 
			selectedItemId={props.selectedItemId} 
			onSelectItem={props.onSelectItem}
			ke_transcript_id = {props.ke_transcript_id} 
			onUtteranceNumListSelect={props.onUtteranceSelect}
			/>
	    <PreparedRemarksBlocks 
			selectedItemId={props.selectedItemId} 
			onSelectItem={props.onSelectItem}
			ke_transcript_id = {props.ke_transcript_id} 
			onUtteranceNumListSelect={props.onUtteranceSelect}
			/>
		<QuestionAnswerBlocks 
			selectedItemId={props.selectedItemId} 
			onSelectItem={props.onSelectItem}
			ke_transcript_id = {props.ke_transcript_id} 
			onUtteranceNumListSelect={props.onUtteranceSelect}
			/>
	  </div>
	</Box>
  );
};

export default TranscriptsSummaryPane;
