import React, { useState } from 'react';
import { Box } from '@mui/material';
import FlexDivider from "../../Miscellaneous/FlexDivider";
import EventList from "./EventList/EventList";
import CompanyEventAnalysis from './Analysis/CompanyEventAnalysis';
import CompanyDetail from "./Detail/CompanyDetail";
import "./CompanyEvents.css";

function CompanyEvents() {
    const [paneWidths, setPaneWidths] = useState([14,43,43]);
  
    const onDrag = (e, index) => {
      const newWidths = [...paneWidths];
      
      // Get the current mouse position as a percentage of the total window width
      const mousePositionPercent = (e.clientX / window.innerWidth) * 100;
      
      if (index === 0) {
        // If dragging the first divider
        newWidths[0] = mousePositionPercent;
        newWidths[1] = 100 - mousePositionPercent - paneWidths[2];
      } else if (index === 1) {
        // If dragging the second divider
        newWidths[1] = mousePositionPercent - paneWidths[0];
        newWidths[2] = 100 - mousePositionPercent;
      }
    
      setPaneWidths(newWidths);
    };
    
    return (
        <Box className="company-events-container">
          <Box width={`${paneWidths[0]}%`}>
            <EventList />
          </Box>
          <FlexDivider onDrag={onDrag} index={0} direction="vertical" />
          <Box width={`${paneWidths[1]}%`}>
            <CompanyEventAnalysis />
          </Box>
          <FlexDivider onDrag={onDrag} index={1} direction="vertical" />
          <Box width={`${paneWidths[2]}%`}>
            <CompanyDetail />
          </Box>
        </Box>
    );
  };
  
  export default CompanyEvents;