import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import {Box, Link, Grid, useTheme} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Auth } from 'aws-amplify';
import Alert from '@mui/material/Alert';
import { logUserEvent } from './UserFunctions';

function SignIn({ onClose, onSignIn, onForgotPasswordClick }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const theme = useTheme();

  const signInButtonStyle = {
    backgroundColor: theme.palette.ke.medium_blue, // Use medium_blue for button
    mt: 3, 
    mb: 2,
    '&:hover': {
      backgroundColor: theme.palette.ke.dark_blue // Darker blue on hover
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError('');
    try {
      await Auth.signIn(email, password);
      logUserEvent('login');
      onSignIn();
      navigate('/');
    } catch (error) {
      console.error('Error signing in: ', error);
      setError('Incorrect email or password. Please try again.');
    }
  };

  return (
    <Box className="main-component-container"  sx={(theme) => ({ background: theme.palette.ke.background, height: "100%"})}>
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Avatar sx={{ m: 1, bgcolor: theme.palette.ke.medium_grey }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Sign in
        </Typography>
        {error && (
          <Alert severity="error" sx={{ width: '100%', mt: 2 }}>
            {error}
          </Alert>
        )}
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="password"
            label="Password"
            type="password"
            id="password"
            autoComplete="current-password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={signInButtonStyle}
          >
            Sign In
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2" onClick={() => navigate('/forgotpassword')}>
                Forgot password?
              </Link>
            </Grid>
            <Grid item>
              <Link href="#" variant="body2" onClick={() => navigate('/signup')}>
                {"Don't have an account? Register here."}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
    </Box>
  );
}

export default SignIn;
