// ResetPassword.js

import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import {
  Container,
  Box,
  Avatar,
  Typography,
  TextField,
  Button, 
  Alert, 
  useTheme
} from '@mui/material';
import LockResetIcon from '@mui/icons-material/LockReset';
import { useNavigate, useLocation } from 'react-router-dom';

function ResetPassword() {
  const [code, setCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [isReset, setIsReset] = useState(false); 
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const { email } = location.state || { email: '' }; 
  const theme = useTheme();

  const resetPasswordButtonStyle = {
    backgroundColor: theme.palette.ke.medium_blue, // Use medium_blue for button
    mt: 3, 
    mb: 2,
    '&:hover': {
      backgroundColor: theme.palette.ke.dark_blue // Darker blue on hover
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError('');
    try {
      await Auth.forgotPasswordSubmit(email, code, newPassword);
      setIsReset(true);
    } catch (error) {
      console.error('Error resetting password:', error);
      setError(error.message || 'An error occurred. Please check to assure that you are using the most recent code sent by email.');
    }
  };

  if (isReset) {
    return (
      <Box className="main-component-container"  sx={(theme) => ({ background: theme.palette.ke.background, height: "100%"})}>
      <Container component="main" maxWidth="xs">
        <Box sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
          <Typography component="h1" variant="h5">
            Your password has been reset successfully.
          </Typography>
          <Button
            type="button"
            fullWidth
            variant="contained"
            sx={resetPasswordButtonStyle}
            onClick={() => navigate('/signin')}
          >
            Proceed to Sign-In
          </Button>
        </Box>
      </Container>
      </Box>
    );
  }

  return (
    <Box className="main-component-container"  sx={(theme) => ({ background: theme.palette.ke.background, height: "100%"})}>
    <Container component="main" maxWidth="xs">
      <Box sx={{
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}>
        <Avatar sx={{ m: 1, bgcolor: theme.palette.ke.medium_grey }}>
          <LockResetIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Reset Password
        </Typography>
        <span>Please check your email for the verification code.</span>
        {error && (
          <Alert severity="error" sx={{ width: '100%', mt: 2 }}>
            {error}
          </Alert>
        )}
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            name="code"
            label="Verification Code"
            type="text"
            id="code"
            value={code}
            onChange={(e) => setCode(e.target.value)}
            autoFocus
          />
          <TextField
            margin="normal"
            required
            fullWidth
            name="newPassword"
            label="New Password"
            type="password"
            id="newPassword"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={resetPasswordButtonStyle}
          >
            Reset Password
          </Button>
        </Box>
      </Box>
    </Container>
    </Box>
  );
}

export default ResetPassword;
