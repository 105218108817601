import React, { useState } from 'react';
import { Box } from '@mui/material';
import TranscriptsSummaryPane from './Summary/TranscriptsSummaryPane';
import TranscriptsDetailPane from './Detail/TranscriptsDetailPane';
import TranscriptsSidebar from './TranscriptsSidebar';
import './Transcripts.css';

function Transcripts() {
  const [selectedKeTranscriptId, setSelectedKeTranscriptId] = useState();
  const [selectedCallTitle, setSelectedCallTitle] = useState();
  const [selectedTicker, setSelectedTicker] = useState();
  const [selectedAISummary, setSelectedAISummary] = useState();
  const [selectedUtteranceNums, setSelectedUtteranceNums] = useState([]);

  const handleTranscriptSelect = (ke_transcript_id, call_title, ticker,ai_summary) => {
    //console.log("Selected AI Summary:", ai_summary);
    setSelectedKeTranscriptId(ke_transcript_id);
    setSelectedCallTitle(call_title);
    setSelectedTicker(ticker);
    setSelectedAISummary(ai_summary);
    setSelectedUtteranceNums([]);
    setSelectedItemId();
  };

  const handleUtteranceSelection = (utterance_num_list) => {
    setSelectedUtteranceNums(utterance_num_list);
  };
  
	const [selectedItemId, setSelectedItemId] = useState(null);

	const handleSelectItem = (itemId) => {
		setSelectedItemId(itemId);
	  };

  return (
    <Box className='main-component-container transcript-main' sx={(theme) => ({ background: theme.palette.ke.background})}>
      <TranscriptsSidebar 
        onTranscriptSelect={handleTranscriptSelect}
      />
      <div className='transcript-content-container'>
      <div className='transcript-pane transcript-summary-pane'>
        <TranscriptsSummaryPane 
          ke_transcript_id={selectedKeTranscriptId} 
          selectedCallTitle = {selectedCallTitle}
          selectedTicker = {selectedTicker}
          selectedAISummary = {selectedAISummary}
          onUtteranceSelect={handleUtteranceSelection}
          selectedItemId={selectedItemId}
          onSelectItem={handleSelectItem}
          />
      </div>
      <div className='transcript-right-pane'>
        <div className='transcript-detail-pane'>
          <TranscriptsDetailPane 
            ke_transcript_id={selectedKeTranscriptId} 
            selectedCallTitle = {selectedCallTitle}
            selectedTicker = {selectedTicker}
            utterance_num_list={selectedUtteranceNums}
            />
        </div> 
        </div>
      </div>
    </Box>
  );
}

export default Transcripts;

