import React from 'react';
import { Typography } from '@mui/material';
import { TERMS_LAST_UPDATED } from '../Main/Constants';
import { format } from 'date-fns';
import { Link as RouterLink } from 'react-router-dom';

function TermsText() {
  const formattedDate = format(new Date(TERMS_LAST_UPDATED), 'MMMM d, yyyy');
  const currentYear = new Date().getFullYear(); 
  return (
      <div>
      <Typography variant="body2" paragraph>Last updated: {formattedDate}</Typography>
      <Typography variant="body2" paragraph>
      Websites operated by Kessler Energy, LLC (collectively "Websites"), including 
      but not limited to the domains kesslerenergy.com and alpha-pilot.ai, and all content available therein 
      (including "Products" and "Trial Products") are the exclusive property of Kessler Energy, LLC 
      and/or its affiliates (collectively "KE" or "Kessler"). By accessing the sites, 
      individuals acting on behalf of Clients (aka "Authorized Users") agree to be bound 
      by the current "Terms of Use" (this document), "Privacy Policy" (available <RouterLink to="/privacy">here</RouterLink>), 
      and the applicable "Master Agreement" or "Standard Master Agreement" 
      available <a href="https://agreement.kessler.energy" target="_blank" rel="noopener noreferrer">here</a>.
      </Typography>
      <Typography variant="body2" paragraph>
      Authorized Users and Other Users are referred to herein collectively as "Users". 
      The Terms of Use, Privacy Policy, and Standard Master Agreement may be amended or 
      restated from time to time at KE's sole discretion and are made available on the Websites 
      to authenticated users. Users indicate their acceptance of revised or amended terms by 
      continuing to access the Websites after the amended or restated version of the Terms of 
      Use, Privacy Policy and/or Standard Master Agreement have been posted to the Websites.
      </Typography>
      <Typography variant="body2" paragraph>
      The terms "Website(s)", "Product(s)", "Trial Product(s)" and "Authorized User(s)" are 
      as defined in the corresponding Master Agreement or Standard Master Agreement.
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
      Copyright, License and Site Access
      </Typography>
      <Typography variant="body2" paragraph>
      All content included on the Websites, such as text, graphics, logos, button icons, 
      images, audio clips, digital downloads, data compilations, and software, is the 
      property of KE or its content suppliers and is protected by United States and international 
      copyright laws. The compilation of all content on the Websites is the exclusive property 
      of KE and protected by U.S. and international copyright laws. All software used on the 
      Websites is the property of KE or its software suppliers and is protected by United States 
      and international copyright laws. The Websites or any portion of the Websites may not be 
      reproduced, duplicated, copied, sold, resold, visited, or otherwise exploited for any 
      commercial purpose without express written consent of KE. All persons or entities accessing 
      the Websites, including Users, are prohibited from using any automated communication tool 
      to access the Websites, including but not limited to screen scraping or other data extraction 
      tools and may not frame or utilize framing techniques to enclose any trademark, logo or other 
      proprietary information (including images, text, page layout, or form) of KE. Use of meta tags 
      or any other "hidden text" utilizing KE name or trademarks without the express written 
      consent of KE is also prohibited. Any unauthorized use of the Websites or violation of any 
      of the Terms of Use listed herein terminates the permission or license granted by KE.
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
      User Account
      </Typography>
      <Typography variant="body2" paragraph>
      Users are responsible for maintaining the confidentiality of their login credentials and 
      for restricting access to their computers and agree to accept responsibility for all 
      activities that occur using those credentials. Access to the site by anyone under the 
      age of 18 is prohibited.
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
      Use of Information and Websites
      </Typography>
      <Typography variant="body2" paragraph>
      Subject to the terms of the applicable Master Agreement or Standard Master Agreement, 
      Users may not, without KE's prior express written consent, reproduce, distribute, modify, 
      display, prepare derivative works based on, repost or otherwise use any of the content of 
      the Websites. Users may not use the Websites or any content of the Websites for any 
      unlawful purpose. Users may not disrupt, alter, impair or modify the Websites or anything 
      contained in the Websites, other than through the purposely designed user input forms for 
      their intended purpose. Users may not access any private information, including passwords 
      or account information of other users of the Website.
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
      Disclaimer and Limitation of Liability
      </Typography>
      <Typography variant="body2" paragraph>
      KE makes no warranties or representations as to the accuracy or completeness of 
      information included on the Websites and assumes no liability or responsibility 
      for any errors or omissions in the information provided therein.
      </Typography>
      <Typography variant="body2" paragraph>
      Users expressly acknowledge and agree that their use of the Websites is at their own 
      risk. All information available through the Websites is provided on an "AS IS" basis. 
      KE makes no representations or warranties that the Website will meet Users' requirements, 
      be uninterrupted, secure or error free or that the Websites or the servers that make 
      them available are free of viruses or other harmful components or destructive files.
      </Typography>
      <Typography variant="body2" paragraph>
      The content of the Websites is subject to change without notice.
      </Typography>
      <Typography variant="body2" paragraph>
      The Websites may contain facts, views, opinions, statements and recommendations 
      of KE and/or third-party individuals, entities or organizations. KE does not 
      represent, warrant or endorse the accuracy, timeliness or reliability of any 
      advice, opinion, statement or other information displayed, uploaded or distributed 
      through the Websites. Users acknowledge and agree that any reliance upon such 
      opinion, advice, statement or information is at their sole risk.
      </Typography>
      <Typography variant="body2" paragraph>
      TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, KE PROVIDES THE WEBSITES, 
      THE INFORMATION ON THE WEBSITES AND THE PRODUCT(S) TO USERS ON AN "AS IS," AND 
      "AS AVAILABLE" BASIS WITHOUT WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AND 
      KE DISCLAIMS ALL WARRANTIES OF ANY KIND, INCLUDING WITHOUT LIMITATION ANY IMPLIED 
      WARRANTIES OF SATISFACTORY QUALITY, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, 
      TITLE OR NON-INFRINGEMENT. KE ASSUMES NO RESPONSIBILITY, AND IS NOT LIABLE FOR, ANY 
      DAMAGES TO, OR VIRUSES THAT MAY INFECT, THE USERS' COMPUTER EQUIPMENT OR OTHER 
      PROPERTY ON ACCOUNT OF THEIR ACCESS TO OR BROWSING IN THE WEBSITES, OR THE 
      DOWNLOADING OF ANY MATERIALS, DATA, TEXT OR IMAGES FROM THE WEBSITES.
      </Typography>
      <Typography variant="body2" paragraph>
      KESSLER SHALL NOT BE LIABLE TO CLIENT OR ANY USER FOR ANY INDIRECT, SPECIAL, 
      INCIDENTAL, EXEMPLARY, CONSEQUENTIAL, OR PUNITIVE DAMAGES ARISING OUT OF USERS 
      ACCESS TO OR USE OF ANY PRODUCTS OR THE WEBSITES EVEN IF KESSLER HAS BEEN 
      ADVISED OF THE POSSIBILITY OF THE OCCURRENCE OF SUCH DAMAGES.
      </Typography>
      <Typography variant="body2" paragraph>
      IF KESSLER BECOMES LIABLE TO CLIENT FOR ANY REASON, WHETHER ARISING BY 
      NEGLIGENCE, INTENDED CONDUCT OR OTHERWISE, SUCH LIABILITY SHALL NOT EXCEED 
      IN THE AGGREGATE FOR ALL EVENTS THE AMOUNTS PAID BY CLIENT TO KESSLER IN THE 
      TWELVE MONTH PERIOD PRECEDING THE EVENT GIVING RISE TO SUCH CLAIM. 
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
      Indemnity
      </Typography>
      <Typography variant="body2" paragraph>
      Users agree to defend, indemnify, hold harmless  Kessler its affiliates, 
      and their respective officers, directors, employees, agents and representatives 
      ("Kessler Group") from and against any and all costs, liabilities, losses, and 
      expenses (including, but not limited to, reasonable attorneys' fees) incurred 
      by any member of the Kessler Group resulting from any third party claim, suit, 
      action, or proceeding brought against any member of the Kessler Group arising 
      from or in connection with User's access to or use of the Websites or the Products.
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
      Termination of Usage
      </Typography>
      <Typography variant="body2" paragraph>
      KE may modify, suspend, discontinue, restrict or terminate User access to the 
      Websites at any time, without notice or liability other than per the terms of 
      the Agreement.
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
      Notice and Procedure for Copyright Infringement Claim
      </Typography>
      <Typography variant="body2" paragraph>
      KE, pursuant to 17 U.S.C. Section 512 as amended by Title II of the Digital 
      Millennium Copyright Act (the "Act"), reserves the right, but not the obligation, 
      to terminate User's access to or use of the Websites if it determines in its 
      sole and absolute discretion that you are involved in infringing activity, including 
      alleged acts of first-time or repeat infringement, regardless of whether the 
      material or activity is ultimately determined to be infringing. KE accommodates 
      and does not interfere with standard technical measures used by copyright owners 
      to protect their materials. In addition, pursuant to 17 U.S.C. Section 512(c), 
      KE implemented procedures for receiving written notification of claimed 
      infringements and for processing such claims in accordance with the Act. 
      KE's designated agent to receive notification of claimed infringement is:
      Website Posting Agent  
      <br /><br />
      Kessler Energy, LLC<br />
      1415 South Voss, Suite 110-421<br />
      Houston, TX 77057<br />
      support@kesslerenergy.com
      </Typography>
      <Typography variant="body2" paragraph>
      In addition, any written notice regarding any defamatory or infringing activity, 
      whether of a copyright, patent, trademark or other proprietary right, should be 
      sent to KE's designated agent, listed above, and must include the 
      following information:<br /><br />
      a) A physical or electronic signature of a person authorized to act on behalf 
      of (1) the owner of an exclusive right that is allegedly infringed or (2) the 
      person defamed.<br /><br />
      b) Identification of the copyrighted work claimed to have been infringed, or, 
      if multiple copyrighted works at a single online site are covered by a single 
      notification, a representative list of such works at that site. Similarly for 
      other types of infringing materials, a list of such materials.<br /><br />
      c) Identification of the material that is claimed to be infringing, to be the 
      subject of infringing activity, or that is claimed to be defamatory and that is 
      to be removed or access to which is to be disabled, and information reasonably 
      sufficient to permit us to locate the material.<br /><br />
      d) Information reasonably sufficient to permit us to contact you, such as your 
      address, telephone number, and/or electronic mail address.<br /><br />
      e) A statement that you have a good faith belief that use of the material in the 
      manner complained of is not authorized by the copyright or other proprietary 
      right owner, its agent, or the law.<br /><br />
      f) A statement that the information in the notification is accurate, and under 
      penalty of perjury, that you are authorized to act on behalf of the owner of 
      an exclusive right that is allegedly infringed or on behalf of the person defamed.
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
      General
      </Typography>
      <Typography variant="body2" paragraph>
      KE may revise these Terms of Use from time to time by updating or revising this 
      posting, with the revised Terms of Use taking effect as of the date of posting. 
      Continued use of the Websites by Users after such update or revision shall 
      constitute agreement to be bound by any such updates or revisions. If any provision 
      of these Terms of Use is held to be unenforceable, the unenforceable portion shall 
      be construed in accordance with applicable law to the greatest extent possible and 
      the remainder of the provisions shall remain in full force and effect. The Terms of 
      Use shall be governed by and construed and enforced in accordance with the laws of 
      the State of Texas, without regard to its conflicts of law principles. Users hereby 
      agree that any cause of action that they may have with respect to KE must be filed 
      in a state court in Houston, Texas within two (2) months of the time in which the 
      events giving rise to such claim began, or they agree to waive such claim.
      </Typography>
      <Typography variant="body2" paragraph>
      In the event of conflict between these Terms of Use and a Master Agreement or 
      Standard Master Agreement, the Master Agreement or Standard Master Agreement shall 
      govern for the item(s) in conflict.
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
      Contact Information
      </Typography>
      <Typography variant="body2" paragraph>
      Questions about these Terms of Use, Privacy Policy, Standard Master Agreement, 
      the practices of the Websites or other matters relating to KE may be directed, 
      in writing only, to:
      <br /><br />
      Kessler Energy, LLC<br />
      1415 South Voss, Suite 110-421<br />
      Houston, TX 77057<br />
      </Typography>
      <Typography variant="body2" paragraph>
      BY VISITING THE WEBSITES AND/OR REQUESTING INFORMATION, PRODUCTS OR SERVICES, 
      USERS AGREE TO BE BOUND BY THESE TERMS OF USE AND THE PRIVACY POLICY. IF YOU 
      DO NOT AGREE WITH THE TERMS OF USE AND PRIVACY POLICY, PLEASE DO NOT PROVIDE 
      ANY INFORMATION OR USE ANY OF THE SERVICES OR PRODUCTS OFFERED OR PROVIDED 
      ON ANY OF THE WEBSITES REFERRED TO HEREIN.
      </Typography>
      <Typography variant="body2" paragraph>
      PLEASE NOTE: THE TERMS OF USE AND PRIVACY POLICY REFERENCED HERE CHANGE FROM 
      TIME TO TIME AND CHANGES ARE EFFECTIVE UPON POSTING. PLEASE CHECK BACK 
      FREQUENTLY FOR UPDATES AS IT IS THE SOLE RESPONSIBILITY OF USERS TO BE 
      AWARE OF CHANGES. KE DOES NOT PROVIDE NOTICES OF CHANGES IN ANY MANNER 
      OTHER THAN BY POSTING THE CHANGES ON THIS WEB SITE.
      </Typography>
      <Typography variant="body2" paragraph>
      © {currentYear} Kessler Energy
      </Typography>   
    </div>
  )
}

export default TermsText;