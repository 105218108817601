import React from 'react';
import { DataGrid } from "@mui/x-data-grid";
import { Box } from '@mui/material';
import "./EventList.css";

// Example placeholder data
const rows = [
  {
    company_event_id: '7b9301c3-f2f2-4c1f-b7b9-9a934baff7e8',
    event_title: "4Q'23",
    event_utc: '2024-02-22T15:00:00.000000Z',
  },
  {
    company_event_id: '7e541b48-5e05-4dbc-bf41-682e13e8316e',
    event_title: "1Q'24",
    event_utc: '2024-04-25T15:00:00.000000Z',
  },
];

// Utility functions to extract date and time
function extractDateFromUTC(dateString) {
  const date = new Date(dateString);
  return `${date.getUTCMonth() + 1}-${date.getUTCDate()}-${date.getUTCFullYear()}`;
}

function extractTimeFromUTC(dateString) {
  const date = new Date(dateString);
  const options = {
    timeZone: "America/New_York",
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  };
  return date.toLocaleTimeString("en-US", options);
}

// Columns for the DataGrid
const columns = [
  {
    field: 'date',
    headerName: 'Date',
    flex: 1,
    valueGetter: (params) => extractDateFromUTC(params.row.event_utc),
  },
  {
    field: 'time',
    headerName: 'Time (ET)',
    flex: 1,
    valueGetter: (params) => extractTimeFromUTC(params.row.event_utc),
  },
  {
    field: 'event_title',
    headerName: 'Event',
    flex: 1,
  },
];

const EventList = () => {
  return (
    <Box className="event-list-box">
      <DataGrid
        className="event-list-grid"
        rows={rows}
        columns={columns}
        getRowId={(row) => row.company_event_id}
        hideFooter
        rowHeight={30}
        disableExtendRowFullWidth={true}
      />
    </Box>
  );
};

export default EventList;
