import React, { useState, useContext } from 'react';
import { Auth } from 'aws-amplify';
import { useNavigate } from 'react-router-dom';
import {
  Container,
  Box,
  Avatar,
  Typography,
  TextField,
  Button,
  Grid,
  Link,
  useTheme
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import UserContext from './UserContext';


function ConfirmSignUp() {
  const { email } = useContext(UserContext);
  const [code, setCode] = useState('');
  const [confirmed, setConfirmed] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();

  const confirmAccountButtonStyle = {
    backgroundColor: theme.palette.ke.medium_blue, // Use medium_blue for button
    mt: 3, 
    mb: 2,
    '&:hover': {
      backgroundColor: theme.palette.ke.dark_blue // Darker blue on hover
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await Auth.confirmSignUp(email, code);
      setConfirmed(true);
    } catch (error) {
      console.error('Error confirming sign up', error);
    }
  };

  if (confirmed) {
    return (
      <Box className="main-component-container"  sx={(theme) => ({ background: theme.palette.ke.background, height: "100%"})}>
      <Container component="main" maxWidth="xs">
        <Box sx={{
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'
        }}>
          <Typography component="h1" variant="h5">
            Your account has been verified.
          </Typography>
          <Button
            variant="contained"
            sx={confirmAccountButtonStyle}
            onClick={() => navigate('/signin')}
          >
            Please proceed to sign-in
          </Button>
        </Box>
      </Container>
      </Box>
    );
  }

  return (
    <Box className="main-component-container"  sx={(theme) => ({ background: theme.palette.ke.background, height: "100%"})}>
    <Container component="main" maxWidth="xs">
      
      <Box sx={{
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}>
        <Avatar sx={{ m: 1, bgcolor: theme.palette.ke.medium_grey }}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Confirm Account
        </Typography>
        <span>Please check your email for the verification code.</span>
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="verificationCode"
            label="Verification Code"
            name="verificationCode"
            autoComplete="one-time-code"
            autoFocus
            value={code}
            onChange={(e) => setCode(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={confirmAccountButtonStyle}
          >
            Confirm Account
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2" onClick={() => navigate('/signin')}>
                Back to Sign In
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
    </Box>
  );
}

export default ConfirmSignUp;
