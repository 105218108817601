import React, { useEffect, useState } from "react";
import './AISummarySection.css'

const AISummarySection = (props) => {

    if (!props.AISummary) {
        // If AISummary is empty or undefined, return an empty div
        return <div></div>;
      }
      
    return (
    <div className = 'ai-summary-section' style={{ overflowY: 'auto' }}>
        <div><b>AI SUMMARY:</b></div>
        <br></br>
        {props.AISummary}
    </div>
    );
};

export default AISummarySection;