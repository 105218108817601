import React, { useState, useContext } from "react";
import { Auth } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import {Box, Grid, Link, useTheme} from "@mui/material";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import UserContext from "./UserContext";
import Alert from "@mui/material/Alert";
import { Link as RouterLink } from 'react-router-dom';
import { logUserAgreement } from './UserFunctions';

const theme = createTheme();

function SignUp() {
  const { email, setEmail } = useContext(UserContext);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [agreement, setAgreement] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const theme = useTheme();

  const signUpButtonStyle = {
    backgroundColor: theme.palette.ke.medium_blue, // Use medium_blue for button
    mt: 3, 
    mb: 2,
    '&:hover': {
      backgroundColor: theme.palette.ke.dark_blue // Darker blue on hover
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError("");
    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }
    if (!agreement) {
      setError("Please agree to the terms and conditions.");
      return;
    }
    try {
      await Auth.signUp({
        username: email,
        password,
        attributes: {
          email,
          given_name: firstName,
          family_name: lastName,
        },
      });
      setEmail(email);
      logUserAgreement();
      navigate("/verification");
    } catch (error) {
      if (error.code === "UsernameExistsException") {
        setError(
          <span>
            An account with the given email already exists. If you forgot your password, you can 
            <RouterLink to="/forgotpassword" style={{ marginLeft: '4px' }}>
              reset it here.
            </RouterLink>
          </span>
        );
      } else {
        setError("An error occurred during registration. Please try again.");
      }
      console.error("Error signing up: ", error);
    }
  };

  return (
    <Box className="main-component-container"  sx={(theme) => ({ background: theme.palette.ke.background, height: "100%"})}>
      <Container component="main" maxWidth="xs">
        <Box
          sx={{
            marginTop: 8,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: theme.palette.ke.medium_grey }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Alpha-Pilot Registration
          </Typography>
          <Box
            component="form"
            noValidate
            onSubmit={handleSubmit}
            sx={{ mt: 3 }}
          >
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                  value={firstName}
                  onChange={(e) => setFirstName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="family-name"
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address (work)"
                  name="email"
                  autoComplete="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="confirmPassword"
                  label="Confirm Password"
                  type="password"
                  id="confirmPassword"
                  value={confirmPassword}
                  onChange={(e) => setConfirmPassword(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={agreement}
                      onChange={(e) => setAgreement(e.target.checked)}
                    />
                  }
                  label={
                    <Typography>
                      I acknowledge receipt of and agree to the following{' '}
                      <RouterLink to="/terms">terms</RouterLink>,{' '}
                      <RouterLink to="/disclaimers">disclaimers</RouterLink>, and{' '}
                      <RouterLink to="/privacy">privacy policy</RouterLink>,{' '}
                      as may be updated from time to time.
                    </Typography>
                  }
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={signUpButtonStyle}
            >
              Register
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <Link
                  href="#"
                  variant="body2"
                  onClick={() => navigate("/signin")}
                >
                  Already have an account? Sign in
                </Link>
              </Grid>
            </Grid>
          </Box>

          {error && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {error}
            </Alert>
          )}

        </Box>
      </Container>
    </Box>
  );
}

export default SignUp;
