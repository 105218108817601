import React, { useEffect } from 'react';
import { Box } from '@mui/material';
import './Welcome.css'

import Button from '@mui/material/Button';
import { getPowerBIReportAccessDetails } from '../User/UserFunctions';
import { logUserEvent } from '../User/UserFunctions';

function Welcome(props) {

  const calendlyUrl = "https://calendly.com/kessler-energy/30min?hide_gdpr_banner=1";

  useEffect(() => {
    const head = document.querySelector('head');
    const script = document.createElement('script');
    script.setAttribute('src',  'https://assets.calendly.com/assets/external/widget.js');
    head.appendChild(script);
  }, []);

  return (
    <Box className="main-component-container"  sx={(theme) => ({ background: theme.palette.ke.background})}>
      <header className="header-section" style={{width: '100%'}}>
        <h2 className="header-sub-2">Welcome!</h2>
        <p className="header-sub-3">Please book a demo call below for access to specific Alpha-Pilot features.</p>
        <div className="calendly-inline-widget" data-url={calendlyUrl} style={{
              minWidth: '320px', 
              width: '100%', 
              height: '700px'
              }}></div>
      </header>
    </Box>
  );
}

export default Welcome;