import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import "./CompanySummary.css";

import { API, graphqlOperation } from "aws-amplify";
import { listCompanies } from "../../../graphql_ap/queries";

function CompanySummary() {
    
    return (
        <Box className="company-summary-container">
          Placeholder Text
        </Box>
    );
  };
  
  export default CompanySummary;