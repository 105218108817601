import React from 'react';
import { Button, Box, useTheme } from '@mui/material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'; // PDF Icon
import DescriptionIcon from '@mui/icons-material/Description'; // Word or Text Icon
import GridOnIcon from '@mui/icons-material/GridOn'; // New Excel Icon
import LanguageIcon from '@mui/icons-material/Language'; // HTML Icon
import AttachFileIcon from '@mui/icons-material/AttachFile'; // Generic Document Icon
import AssessmentIcon from '@mui/icons-material/Assessment'; // SEC/XBRL Icon

const documents = [
  { title: "Press Release", type: "html" },
  { title: "IR Supplement", type: "pdf" },
  { title: "10-Q", type: "sec" },
  { title: "Trading Indicators", type: "excel" },
  { title: "Transcript", type: "word" }
];

const getIconByType = (type) => {
  switch (type) {
    case 'pdf':
      return <PictureAsPdfIcon />;
    case 'excel':
      return <GridOnIcon />;
    case 'word':
    case 'text':
      return <DescriptionIcon />;
    case 'html':
      return <LanguageIcon />;
    case 'sec':
      return <AssessmentIcon />;
    default:
      return <AttachFileIcon />; // Generic document icon
  }
};

const DocumentButtons = () => {
  const theme = useTheme(); // Get the theme object

  return (
    <Box
      sx={{
        display: 'flex',
        overflowX: 'auto', // Enables horizontal scrolling
        whiteSpace: 'nowrap',
        padding: '10px',
        gap: '10px', // Gap between buttons
      }}
    >
      {documents.map((doc, index) => (
        <Button
          key={index}
          variant="contained"
          startIcon={getIconByType(doc.type)} // Icon for the document type
          sx={{
            minWidth: '100px', // Fixed width for each button
            textTransform: 'none', // Keeps the title as normal text (not uppercase)
            flexShrink: 0, // Prevents shrinking when there is not enough space
            backgroundColor: theme.palette.ke.medium_blue, // Default background color
            '&:hover': {
              backgroundColor: theme.palette.ke.dark_blue, // Hover background color
            },
            color: 'white', // Text color
          }}
        >
          {doc.title}
        </Button>
      ))}
    </Box>
  );
};

export default DocumentButtons;
