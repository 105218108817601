import React, { useState } from 'react';
import { Box, Tabs, Tab, Typography } from '@mui/material';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Box
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
      sx={{ p: 2 }} // Slight padding for tab content
    >
      {value === index && (
        <Typography component="div">{children}</Typography>
      )}
    </Box>
  );
}

function AnalysisDetail() {
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Box>
      <Tabs
        value={tabValue}
        onChange={handleTabChange}
        aria-label="analysis detail tabs"
        centered
        sx={{ minHeight: '32px' }} // Smaller height for tabs
      >
        <Tab label="Chat" id="tab-0" aria-controls="tabpanel-0" sx={{ minHeight: '32px', fontSize: '0.85rem' }} />
        <Tab label="Transcript" id="tab-1" aria-controls="tabpanel-1" sx={{ minHeight: '32px', fontSize: '0.85rem' }} />
        <Tab label="Call Details" id="tab-2" aria-controls="tabpanel-2" sx={{ minHeight: '32px', fontSize: '0.85rem' }} />
        <Tab label="My Notes" id="tab-3" aria-controls="tabpanel-3" sx={{ minHeight: '32px', fontSize: '0.85rem' }} />
      </Tabs>

      <TabPanel value={tabValue} index={0}>
        Chat Content
      </TabPanel>
      
      <TabPanel value={tabValue} index={1}>
        Transcript Content
      </TabPanel>

      <TabPanel value={tabValue} index={2}>
        Call Details Content
      </TabPanel>

      <TabPanel value={tabValue} index={3}>
        My Notes Content
      </TabPanel>
    </Box>
  );
}

export default AnalysisDetail;
