import React, { useState } from 'react';
import { Box, useTheme } from '@mui/material';

function FlexDivider({ onDrag, index, direction = 'vertical' }) {
  const theme = useTheme();
  const [isDragging, setIsDragging] = useState(false);

  const isVertical = direction === 'vertical';

  const handleMouseDown = (e) => {
    e.preventDefault();
    setIsDragging(true);

    const onMouseMove = (moveEvent) => {
      onDrag(moveEvent, index);
    };

    const onMouseUp = () => {
      setIsDragging(false);
      window.removeEventListener('mousemove', onMouseMove);
      window.removeEventListener('mouseup', onMouseUp);
    };

    window.addEventListener('mousemove', onMouseMove);
    window.addEventListener('mouseup', onMouseUp);
  };

  return (
    <Box
      sx={{
        position: 'relative',
        width: isVertical ? '7px' : '100%',
        height: isVertical ? '100%' : '7px',
        cursor: isVertical ? 'ew-resize' : 'ns-resize',
        '&:hover .divider-line': {
          width: isVertical ? '2px' : '100%',
          height: isVertical ? '100%' : '2px',
          backgroundColor: isDragging ? theme.palette.ke.link_hover : theme.palette.ke.divider_selected,
        },
        '& .divider-line': {
          position: 'absolute',
          top: isVertical ? 0 : '50%',
          bottom: isVertical ? 0 : '50%',
          left: isVertical ? '50%' : 0,
          right: isVertical ? '50%' : 0,
          transform: isVertical ? 'translateX(-50%)' : 'translateY(-50%)',
          width: isVertical ? (isDragging ? '2px' : '0.3px') : '100%',
          height: isVertical ? '100%' : (isDragging ? '2px' : '0.3px'),
          backgroundColor: isDragging ? theme.palette.ke.link_hover : theme.palette.ke.divider_unselected,
          transition: 'width 0.2s, height 0.2s, background-color 0.2s',
        },
      }}
      onMouseDown={handleMouseDown}
    >
      <Box className="divider-line" />
    </Box>
  );
}

export default FlexDivider;
