import React from 'react';
import PdfViewer from './pdfviewer';

function PDFViewerTest() {
  const pdfUrl = 'https://temp-alpha-pilot-public.s3.us-west-2.amazonaws.com/TECK-2024-Q1-Financial-Report-NR.pdf';
  const pageNumber = 2;
  const x1 = 434.04;
  const y1 = 123.399;
  const x2 = 461.83;
  const y2 = 133.399;

  return (
    <div className="App" style={{ width: '100vw', height: '100vh' }}>
      <PdfViewer
        url={pdfUrl}
        pageNumber={pageNumber}
        x1={x1}
        y1={y1}
        x2={x2}
        y2={y2}
      />
    </div>
  );
}

export default PDFViewerTest;
