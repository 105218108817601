import React, {useContext, useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { Button, useTheme } from '@mui/material';
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import TermsText from "./TermsText";
import { useNavigate } from 'react-router-dom';
import CardActions from "@mui/material/CardActions";
import { logUserAgreement } from '../User/UserFunctions';
import { checkUserAcceptance } from '../User/UserFunctions';
import UserContext from "../User/UserContext";
import "./Terms.css";

function Terms({ redirectTo }) {
  const theme = useTheme();
  const { user, hasAcceptedTerms, setHasAcceptedTerms } = useContext(UserContext);
  const navigate = useNavigate();
  const [isButtonVisible, setIsButtonVisible] = useState(true); 

  const acceptTermsButtonStyle = {
    backgroundColor: theme.palette.ke.medium_blue, // Use medium_blue for button
    '&:hover': {
      backgroundColor: theme.palette.ke.dark_blue // Darker blue on hover
    }
  };

  const clickAcceptTermsHandler = async () => {
    setIsButtonVisible(false); // Hide button immediately when clicked
    try {
      await logUserAgreement();  // Call logUserAgreement and wait for it to finish
      setHasAcceptedTerms(true); // Update context to reflect acceptance
      navigate(redirectTo);      // Redirect to the prior page
    } catch (error) {
      console.error('Error logging user agreement')
      setIsButtonVisible(true); // Show button again if there's an error
    }
    };
  
  // Calculate the height dynamically
  const contentHeight = isButtonVisible && user && !hasAcceptedTerms ? 'calc(100% - 150px)' : 'calc(100% - 100px)';


  return (
    <Box className="mainBox" sx={(theme) => ({ background: theme.palette.ke.background})}>
      <Card className="termsCard">
        <CardHeader title="Terms of Use" sx={(theme) => ({ background: theme.palette.ke.card_header, textAlign: 'center', padding: '15px'})}/>
          <CardContent className="customCardContent" style={{ height: contentHeight }}>
            <TermsText/>
          </CardContent>
          {isButtonVisible && user && !hasAcceptedTerms && (
            <CardActions className="cardActions">
            <Button variant="contained" sx={acceptTermsButtonStyle} onClick={clickAcceptTermsHandler}>
              Accept
            </Button>
          </CardActions>
        )}
      </Card>
    </Box>
  );
}

export default Terms;
