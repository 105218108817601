import React from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { Button, useTheme } from '@mui/material';
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import DisclaimersText from "./DisclaimersText";
import { useNavigate } from 'react-router-dom';
import "./Terms.css";

function Disclaimers() {
  const theme = useTheme();

  return (
    <Box className="mainBox" sx={(theme) => ({ background: theme.palette.ke.background})}>
      <Card className="termsCard">
        <CardHeader title="Disclaimers" sx={(theme) => ({ background: theme.palette.ke.card_header, textAlign: 'center', padding: '15px'})}/>
          <CardContent className="customCardContent">
            <DisclaimersText/>
          </CardContent>
      </Card>
    </Box>
  );
}

export default Disclaimers;
