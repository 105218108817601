import React, { useEffect, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { listDynKeTranscriptQuestionAnswerAiBlockBullets } from "../../../graphql_ap/queries";
import { styled } from '@mui/material/styles';

const StyledUl = styled('ul')(({ theme, isSelected }) => ({
  margin: 0,
  padding: 0,
  '&:hover': {
    backgroundColor: theme.palette.action.hover
  },
  // fontWeight: isSelected ? 'bold' : 'normal',
  color: isSelected ? theme.palette.ke.link_hover : 'inherit',
  backgroundColor: isSelected ? '#EEF4FB' : 'inherit', 
}));


const QuestionAnswerBlock = (props) => {

    const [questionAnswerAIBulletsTable, setQuestionAnswerAIBulletsTable] = useState([]);

    useEffect(() => {
        async function fetchData() {
          if (props.question_answer_ai_block_id) {
            try {
              const questionAnswerAIBulletsData = await API.graphql(
                graphqlOperation(listDynKeTranscriptQuestionAnswerAiBlockBullets, {
                  question_answer_ai_block_id: props.question_answer_ai_block_id, 
                })
              );
              const questionAnswerAIBulletsDataList = questionAnswerAIBulletsData.data.listDynKeTranscriptQuestionAnswerAiBlockBullets;
    
              setQuestionAnswerAIBulletsTable(questionAnswerAIBulletsDataList.map(item => ({
                ...item,
                id: item.question_answer_ai_block_bullet_num
              })));
            } catch (error) {
              console.error("Error fetching question answer table:", error);
            }
          }
        }
    
        fetchData();
      }, [props.question_answer_ai_block_id]);

      const itemClickHandler= (bullet) => {
        const itemId = `QuestionAnswerBlock-${bullet.id}`;
        if (props.selectedItemId === itemId) {
          // Deselect the item
          props.onSelectItem(null);
          props.onUtteranceNumListSelect([]);
        } else {
          // Select the item
          props.onSelectItem(itemId);
          props.onUtteranceNumListSelect(bullet.utterance_num_list);
        }
      }

    return (
        <div>
            <p><b>{props.question_answer_block_title}-</b></p>
            {questionAnswerAIBulletsTable
              .sort((a, b) => a.id - b.id)
              .map((bullet, index) => {
                const isSelected = `QuestionAnswerBlock-${bullet.id}` === props.selectedItemId;
                return (
                <div key={index}>
                  <StyledUl onClick={() => itemClickHandler(bullet)}
                  isSelected={isSelected}
                  >
                    <li className="qa-li-question"><b>Q:</b> {bullet.ai_question_text}</li>
                    <ul>
                    {bullet.ai_answer_list && Array.isArray(bullet.ai_answer_list) && 
                      bullet.ai_answer_list.map((answer, ansIndex) => (
                        <li className="qa-li-answer" key={ansIndex}><b>A:</b> {answer}</li>
                      ))
                    }
                    </ul>
                    </StyledUl><br></br>
                </div>
                );
                  })
            }
        </div>
    );
};

export default QuestionAnswerBlock;