import React, { useState } from "react";
import Box from "@mui/material/Box";
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import AnalyticsSidebar from './AnalyticsSidebar';
import { getPowerBIReportAccessDetails } from '../User/UserFunctions';
import { logUserEvent } from '../User/UserFunctions';
import './Analytics.css'

function Analytics() {
  const [loading, setLoading] = useState(false);
  const [reportDetails, setReportDetails] = useState(null);

  const handleSelectedAnalyticID = async (selectedAnalyticID) => {
    setLoading(true); // Start loading
    try {
      const details = await getPowerBIReportAccessDetails(parseInt(selectedAnalyticID, 10));
      setReportDetails(details);
    } catch (error) {
      console.error('Error fetching report details:', error);
    }
    setLoading(false); // End loading
  };
  
  return (
    <Box className="analyticsBody" sx={(theme) => ({ background: theme.palette.ke.background})}>
      <AnalyticsSidebar onSelectAnalyticID={handleSelectedAnalyticID}/>
      {loading ? (
        <div className="splash-screen">Loading analytic...</div>
      ): reportDetails ? (
        <PowerBIEmbed
        cssClassName={'powerbi-container'}
        embedConfig={{
          type: 'report',
          embedUrl: reportDetails.report_url,
          tokenType: models.TokenType.Embed,
          accessToken: reportDetails.embed_token,
          settings: {
            filterPaneEnabled: reportDetails.filter_pane_enabled,
            navContentPaneEnabled: reportDetails.nav_content_pane_enabled,
            background: models.BackgroundType.Transparent,
          },
        }}
        eventHandlers={
          new Map([
            ['loaded', function () { console.log('Report loaded'); }],
            ['rendered', function () { console.log('Report rendered'); }],
            ['error', function (event) { console.error(event.detail); }]
          ])
        }
        getEmbeddedComponent={(embeddedReport) => {
          //console.log('Embedded report object: ', embeddedReport);
        }}
      />
      ): null}
    </Box>
  );
}

export default Analytics;
