import React, { useEffect, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { listDynKeTranscriptQuestionAnswerAiBlocks } from "../../../graphql_ap/queries";
import './QuestionAnswerBlocks.css'
import QuestionAnswerBlock from './QuestionAnswerBlock.js'

const QuestionAnswerBlocks = (props) => {
    const [questionAnswerBlocksTable, setQuestionAnswerBlocksTable] = useState([]);

    useEffect(() => {
        async function fetchData() {
          if (props.ke_transcript_id) {
            try {
              const questionAnswerBlocksData = await API.graphql(
                graphqlOperation(listDynKeTranscriptQuestionAnswerAiBlocks, {
                    ke_transcript_id: props.ke_transcript_id, 
                })
              );
              const questionAnswerBlocksDataList = questionAnswerBlocksData.data.listDynKeTranscriptQuestionAnswerAiBlocks;
              
              setQuestionAnswerBlocksTable(questionAnswerBlocksDataList.map(item => ({
                ...item,
                id: item.question_answer_ai_block_num 
              })));
              
            } catch (error) {
              console.error("Error fetching question answer blocks table:", error);
            }
          }
        }
    
        fetchData();
      }, [props.ke_transcript_id]);

    if (questionAnswerBlocksTable.length === 0) {
        return <div></div>;
      }

    return (
        <div className = "question-answer-section" style={{ overflowY: 'auto' }}>
            <div><b>Q&A:</b></div>
            <br></br>
            {questionAnswerBlocksTable
              .sort((a, b) => a.id - b.id)
              .map((block, index) => (
                <div key={index}>
                    <QuestionAnswerBlock 
                        question_answer_ai_block_id = {block.question_answer_ai_block_id}
                        question_answer_block_title = {block.question_answer_block_title}
                        onUtteranceNumListSelect={props.onUtteranceNumListSelect}
                        onSelectItem={props.onSelectItem} 
                        selectedItemId={props.selectedItemId}
                    />
                </div>
            ))}
        </div>
    );
};

export default QuestionAnswerBlocks;