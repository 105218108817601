import React, { useState, useEffect, useContext } from 'react';
import { Auth } from 'aws-amplify';
import UserContext from './UserContext';

const UserProfile = () => {
    const user = useContext(UserContext);
    const [cognitoUser, setCognitoUser] = useState(null);

    useEffect(() => {
        const fetchUserAttributes = async () => {
            try {
                const currentUser = await Auth.currentAuthenticatedUser();
                const attributes = currentUser.attributes;
                setCognitoUser(attributes);
            } catch (error) {
                console.error('Error fetching user attributes:', error);
            }
        };

        fetchUserAttributes();
    }, [user]);

    return (
        <div>
            <p>Username: {user.username}</p>
            {cognitoUser && (
                <>
                    <p>Email: {cognitoUser.email}</p>
                </>
            )}
        </div>
    );
}

export default UserProfile;
