import React, { useEffect, useRef } from 'react';
import { Box } from '@mui/material'; 
import TranscriptionBlocks from './TranscriptionBlocks.js';

const TranscriptsDetailPane = ({ ke_transcript_id, selectedCallTitle, selectedTicker, utterance_num_list }) => {
  const detailPaneRef = useRef(null);

  useEffect(() => {
    if (ke_transcript_id && detailPaneRef.current) {
      detailPaneRef.current.scrollTop = 0;
    }
  }, [ke_transcript_id]);

  return (
    <Box ref={detailPaneRef} className="card-full-height-main">
      <Box className="card-title-bar" >
        <h1 className="card-main-title">Transcript</h1>
        <p className="card-sub-title">{selectedTicker} - {selectedCallTitle}</p>
      </Box>
      <div className="card-full-height-body">
        <TranscriptionBlocks
          ke_transcript_id={ke_transcript_id}
          utterance_num_list={utterance_num_list}
        />
      </div>
    </Box>
  );
};

export default TranscriptsDetailPane;
