import React, { useState } from 'react';
import { Box, TextField, IconButton, InputAdornment } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { Star, StarBorder, Search } from '@mui/icons-material';
import "./CompanyEventMetricList.css";

const mockData = [
  { id: 1, metric: 'Revenue (USD)', actual: "1Q'24 - $1,367mm", guidance: "2Q'24 - $8,400mm", isFavorite: false },
  { id: 2, metric: 'Production (tonnes)', actual: "1Q'24 - 335", guidance: "2024 - 700 to 1,000", isFavorite: false },
];

const CompanyEventMetricList = () => {
  const [metrics, setMetrics] = useState(mockData);
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value.toLowerCase());
  };

  const handleFavoriteToggle = (id) => {
    setMetrics((prevMetrics) =>
      prevMetrics.map((metric) =>
        metric.id === id ? { ...metric, isFavorite: !metric.isFavorite } : metric
      )
    );
  };

  const filteredMetrics = metrics.filter((metric) =>
    metric.metric.toLowerCase().includes(searchQuery)
  );

  const columns = [
    {
      field: 'isFavorite',
      headerName: '',
      width: 50,
      sortable: false,
      renderCell: (params) => (
        <IconButton
          onClick={() => handleFavoriteToggle(params.row.id)}
          aria-label="favorite"
        >
          {params.row.isFavorite ? <Star color="primary" /> : <StarBorder color="primary" />}
        </IconButton>
      ),
    },
    { field: 'metric', headerName: 'Metric', flex: 1 },
    { field: 'actual', headerName: 'Actual', flex: 1 },
    { field: 'guidance', headerName: 'Guidance', flex: 1 },
  ];

  return (
    <Box className='event-metric-list-boundary-box'>
        <Box sx={{ padding: '10px' }}>
        <TextField
            id="outlined-search"
            placeholder="Search metrics..."
            type="search"
            value={searchQuery}
            onChange={handleSearchChange}
            size="small"
            sx={{ 
              marginBottom: '10px', 
              width: '300px',
              '& .MuiInputBase-root': {
                height: '32px',
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search fontSize="small" />
                </InputAdornment>
              ),
            }}
        />
        <DataGrid
            className="search-data-grid"
            rows={filteredMetrics}
            columns={columns}
            hideFooter
            rowHeight={30}
        />
        </Box>
    </Box>
  );
};

export default CompanyEventMetricList;
