import React, { useState, useEffect } from 'react';
import { Drawer, List, ListItem, ListItemButton, ListItemText, IconButton, Typography, TextField, Box, Divider, Tooltip, tooltipClasses } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useTheme } from '@mui/material/styles';
import { API, graphqlOperation } from "aws-amplify";
import { listDynAnalytics } from "../../graphql_ap/queries";
import { styled } from '@mui/material/styles';
import { logUserEvent } from '../User/UserFunctions';

const drawerWidth = 240;

const AnalyticsSidebar = ({onSelectAnalyticID}) => {
  const theme = useTheme();
  const [open, setOpen] = useState(true); // sidebar is open by default
  const [selectedItem, setSelectedItem] = useState(null); // State to track the selected item
  const [searchInput, setSearchInput] = useState(""); // State for search input
  const [analytics, setAnalytics] = useState([]);

  const sortedAnalytics = analytics.sort((a, b) => a.name.localeCompare(b.name));

  useEffect(() => {
    const fetchData = async () => {
      try {
        const analyticsData = await API.graphql(graphqlOperation(listDynAnalytics));
        setAnalyticsItems(sortedAnalytics);
    
        // Check if the data and listDynAnalytics exist
        if (analyticsData.data && analyticsData.data.listDynAnalytics) {
          const analyticsList = analyticsData.data.listDynAnalytics;
          setAnalytics(analyticsList.map(analytic => ({ ...analytic })));
        } else {
          // Handle the case where data is not in the expected format
          console.error("Received data is not in the expected format", analyticsData);
        }
      } catch (error) {
        console.error("Error fetching analytics:", error);
      }
    };
    
    fetchData();
  }, [sortedAnalytics]);

  const [analyticsItems, setAnalyticsItems] = useState(sortedAnalytics);

  const handleDrawerToggle = () => {
  setOpen(!open);
  };

  const handleDrawerOpen = () => { // used to be able to open the sidebar by clicking anywhere
  if (!open) setOpen(true);
  };

  const handleListItemClick = (item) => {
  setSelectedItem(item.id); // Update the selected item
  logUserEvent('analytic-select', item.name);
  onSelectAnalyticID(item.id); // Call the callback function with the selected id
  };

  const handleFilterChange = (event) => {
  setSearchInput(event.target.value);
  };

  const filteredAnalyticsItems = analyticsItems.filter(
    (item) => item.name.toLowerCase().includes(searchInput.toLowerCase())
    );

  return (
      <Drawer
        variant="permanent"
        open={open}
        onClick={handleDrawerOpen} // top open the sidebar by clicking anywhere
        sx={{
          width: open ? drawerWidth : theme.spacing(7) + 1,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: open ? drawerWidth : theme.spacing(7) + 1,
            boxSizing: 'border-box',
            height: 'calc(100vh - 62px - 24px - 2px)',
            overflow: 'hidden',
            top: 'inherit',
            position: 'relative',
            zIndex: 'auto',
            ...(open ? {} : {
              '&:hover': {
                backgroundColor: 'rgba(0, 0, 0, 0.04)',
              },
            }),
          },
        }}
      >
        {open && (
          <Box sx={{ display: 'flex', alignItems: 'center', p: 1 }}>
            <Typography variant="h6" sx={{ flexGrow: 1, textAlign: 'left', fontWeight: 'bold', paddingLeft: '10px' }}>
              Analytics
            </Typography>
            <IconButton onClick={handleDrawerToggle}>
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </Box>
        )}
        <Divider />
        {!open && (
          <IconButton onClick={handleDrawerToggle} sx={{ width: '100%' }}>
            <MenuIcon />
          </IconButton>
        )}
        {open && <TextField
          id="outlined-search"
          label="search"
          type="search"
          size="small"
          autoFocus // Automatically focus this field
          value={searchInput}
          onChange={handleFilterChange}
          style={{ margin: '10px', marginTop: '20px', mx: 2 }}
        />}
        {open && <List sx={{ 
            overflow: 'auto', 
            height: 'calc(100vh - 62px - 24px - 2px - 150px)', 
            pl: 2, 
            '& .MuiListItemButton-root': {     // Reduces vertical padding
              py: 0.5,                         // Reduces vertical padding
            },                                 // Reduces vertical padding
            '& .MuiListItemText-primary': {    // Smaller text size for list items
              fontSize: '0.875rem',            // Smaller text size for list items
            },                                 // Smaller text size for list items
            '&::-webkit-scrollbar': {
              width: '0.4em',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(0, 0, 0, 0.1)',
              borderRadius: '4px',
            },
            '&:hover::-webkit-scrollbar-thumb': {
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
            },
          }}
        >
          {filteredAnalyticsItems.map((item) => ( 
          // <Tooltip 
          //     key={item.id} 
          //     title={item.description} 
          //     placement="bottom"
          //     PopperProps={{
          //       popperOptions: {
          //         modifiers: [
          //           {
          //             name: 'offset',
          //             options: {
          //               offset: [0, 25], // Adjust this to control the tooltip's position relative to the triggering element
          //             },
          //           },
          //         ],
          //       }
          //     }}
          //     >
            <ListItem disablePadding>
              <ListItemButton onClick={() => handleListItemClick(item)}>
              <ListItemText 
                  primary={item.name}
                  primaryTypographyProps={{
                    fontWeight: selectedItem === item.id ? 'bold' : 'normal', // Bold the text if it's the selected item
                    style: {
                      color: selectedItem === item.id ? theme.palette.ke.link_hover : 'inherit',
                    },
                  }}
                />
              </ListItemButton>
            </ListItem>
            // </Tooltip>
          ))}
        </List>
        }
      </Drawer>
    );
  };

export default AnalyticsSidebar;