import React from 'react';
import { Typography } from '@mui/material';
import { format } from 'date-fns';

function DisclaimersText() {
  const currentYear = new Date().getFullYear(); 
  return (
      <div>
      <Typography variant="body2" paragraph>
      © {currentYear} Kessler Energy, LLC. All rights reserved. By any Kessler Energy product or 
      service, you agree to the "Terms of Use" available here. Authorized Users are 
      subject to the applicable "Master Agreement" or "Standard Master Agreement" 
      available <a href="https://agreement.kessler.energy" target="_blank" rel="noopener noreferrer">here</a>. Unauthorized 
      access, reproduction or forwarding (including electronically via 
      email, file share or otherwise, even for internal use) is strictly prohibited.
      </Typography>
      <Typography variant="body2" paragraph>
      Kessler Energy, LLC is an investment advisor registered under the Investment 
      Advisers Act of 1940. A current version of our Form ADV Part 2a can be 
      found <a href="https://adv2a.kessler.energy" target="_blank" rel="noopener noreferrer">here</a> and 
      Form ADV Part 2b can be 
      found <a href="https://adv2b.kessler.energy" target="_blank" rel="noopener noreferrer">here</a>.
      </Typography>
      <Typography variant="body2" paragraph>
      Certain employees of Kessler Energy and its affiliates are compensated through 
      commissions on the sale of research services. As such, it is possible that this 
      would give rise to a conflict of interest in that these employees may be incentivized 
      to recommend research services based upon the compensation received, and not based 
      on the client's needs. Note, however, that such commission payments are tied to the 
      research provided, and not to whether a client purchases or sells any underlying security.
      </Typography>
      <Typography variant="body2" paragraph>
      An employee of Kessler Energy UK Ltd. that is involved in the preparation of 
      research and investment recommendations also serves as Chief Operating Officer for 
      a North Sea focused private independent oil and gas company. As such, it may be possible 
      that this employee is incentivized to recommend research services based on a desire 
      to positively influence the investing public's perception of the private company, its 
      direct investments and/or operating partners.
      </Typography>
      <Typography variant="body2" paragraph>
      Kessler Energy, LLC and its affiliates ("Kessler") does not warrant, and shall have 
      no responsibility for, the accuracy, timeliness or completeness of the contents of this 
      Product, the underlying data, or any forecasts or analysis provided as part of any 
      "Products" or "Trial Products" as defined in the applicable "Master Agreement" or 
      "Standard Master Agreement", and all such data and information is provided without 
      any warranty of any kind, express or implied. In the case of Alberta, Canada, certain 
      underlying well-level data may be purchased directly from the Alberta Energy Regulator. 
      </Typography>
      <Typography variant="body2" paragraph>
      TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, KESSLER PROVIDES THE PRODUCTS AND 
      TRIAL PRODUCTS ON AN "AS IS" AND "AS AVAILABLE" BASIS WITHOUT WARRANTIES OF ANY KIND, 
      EXPRESS OR IMPLIED, ORAL OR WRITTEN, AND KESSLER EXPRESSLY DISCLAIMS ALL WARRANTIES 
      OF ANY KIND, INCLUDING WITHTOUT LIMITATION ANY IMPLIED WARRANTIES OF SATISFACTORY 
      QUALITY, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE OR NON-INFRINGEMENT. 
      KESSLER SHALL NOT BE LIABLE TO CLIENT OR ANY USER FOR ANY INDIRECT, CONSEQUENTIAL, 
      OR PUNITIVE DAMAGES ARISING OUT OF CLIENT'S OR ANY USER'S ACCESS TO OR USE OF THIS 
      PRODUCT OR OTHER PRODUCTS OR TRIAL PRODUCTS, EVEN IF KESSLER HAS BEEN ADVISED OF THE 
      POSSIBILITY OF THE OCCURRENCE OF SUCH DAMAGES. IF KESSLER BECOMES LIABLE TO CLIENT 
      FOR ANY REASON, SUCH LIABILITY SHALL NOT EXCEED, IN THE AGGREGATE, THE AMOUNTS PAID 
      BY CLIENT TO KESSLER IN THE TWELVE MONTH PERIOD PRECEDING THE EVENT GIVING RISE TO 
      SUCH CLAIM. 
      </Typography>
      <Typography variant="body2" paragraph>
      By using this Product, Client agrees to defend, indemnify, and hold harmless Kessler 
      its affiliates, and their respective officers, directors, employees, agents and 
      representatives ("Kessler Group") from and against any and all costs, liabilities, 
      losses, and expenses (including, but not limited to, reasonable attorneys' fees) 
      incurred by any member of the Kessler Group resulting from any third party claim, 
      suit, action, or proceeding brought against any member of the Kessler Group arising 
      from or in connection with Client's access to or use of the this Product and any 
      other Products or Trial Products.
      </Typography>
      <Typography variant="body2" paragraph>
      In the event of conflict between this Disclaimer and the applicable Master Agreement 
      or Standard Master Agreement the applicable Master Agreement or Standard Master 
      Agreement shall govern for the item(s) in conflict.
      </Typography>
      <Typography variant="body2" paragraph>
      The nature of available data, industry expectations, plans, and company and 
      government action in the public domain are subject to uncertainty.  
      Accordingly, Client acknowledges that actual future events, as well as the current 
      environment may differ substantially from the data, forecasts and opinions included 
      in this Product and any other Products or Trial Products provided to or accessed by 
      Client or its users. PEFORMANCE CANNOT BE GUARANTEED AND ANY INVESTMENT MAY LOSE VALUE. 
      Any investment advice that may be included in this product is intended solely 
      for accredited investors.
      </Typography>
      <Typography variant="body2" paragraph>
      © {currentYear} Kessler Energy
      </Typography>     
    </div>
  )
}

export default DisclaimersText;