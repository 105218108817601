import { createContext } from 'react';

const UserContext = createContext({
  user: null, // The logged-in user object
  email: '', // The email address used during the signup/signin process
  setEmail: () => {}, // Function to update the email
  hasAcceptedTerms: false, // State to track if the user has accepted the terms
  setHasAcceptedTerms: () => {}, // Function to update the terms acceptance state
  redirectPath: '', // The path to redirect to after accepting terms
  setRedirectPath: () => {} // Function to update the redirect path
});

export default UserContext;
