import React, { useEffect, useRef } from 'react';
import { Box } from '@mui/material';
import { Chart, registerables } from 'chart.js';
import './Metric.css';

Chart.register(...registerables);

const Metric = () => {
  const chartRef = useRef(null);
  const chartInstanceRef = useRef(null);
  let resizeTimeout = useRef(null);

  useEffect(() => {
    const ctx = chartRef.current.getContext('2d');

    const data = {
      labels: ["3Q'22", "4Q'22", "1Q'23", "2Q'23", "3Q'23", "4Q'23", "1Q'24", "2Q'24", "3Q'24", "4Q'24"],
      datasets: [
        {
          label: 'Actuals',
          data: [66, 65, 57, 64, 72, 103, 99, 110],
          borderColor: 'rgba(255, 99, 132, 1)',
          backgroundColor: 'rgba(255, 99, 132, 0.5)',
          order: 1,
        },
        {
          label: 'Guidance - 2-22-2024 - Low',
          data: [,,,,,,116.25, 116.25, 116.25, 116.25],
          borderColor: 'rgba(54, 162, 235, 1)',
          backgroundColor: 'rgba(54, 162, 235, 0.5)',
          type: 'line',
          order: 2,
        },
        {
          label: 'Guidance - 2-22-2024 - High',
          data: [,,,,,,135, 135, 135, 135],
          borderColor: 'rgba(54, 162, 235, 1)',
          backgroundColor: 'rgba(54, 162, 235, 0.5)',
          type: 'line',
          order: 3,
        },
      ],
    };

    const config = {
      type: 'bar',
      data: data,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            position: 'top',
          },
          title: {
            display: true,
            text: 'Copper Production',
          },
        },
      },
    };

    // Create chart instance
    chartInstanceRef.current = new Chart(ctx, config);

    // Add resize observer to resize the chart dynamically
    const resizeObserver = new ResizeObserver(() => {
      // Throttle the resize to avoid triggering it too frequently
      if (resizeTimeout.current) {
        clearTimeout(resizeTimeout.current);
      }
      resizeTimeout.current = setTimeout(() => {
        chartInstanceRef.current.resize();
      }, 200); // Adjust the delay as needed
    });

    resizeObserver.observe(chartRef.current);

    // Cleanup on unmount
    return () => {
      resizeObserver.disconnect();
      chartInstanceRef.current.destroy();
      if (resizeTimeout.current) {
        clearTimeout(resizeTimeout.current);
      }
    };
  }, []);

  return (
    <Box className='metric-boundary-box' sx={{ width: '100%', height: '100%' }}>
      <canvas ref={chartRef}></canvas>
    </Box>
  );
};

export default Metric;
