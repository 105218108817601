import React, { useState, useRef } from 'react';
import { Box } from '@mui/material';
import FlexDivider from "../../../Miscellaneous/FlexDivider";
import Metric from "./Metric/Metric.js"
import "./CompanyDetail.css";

function CompanyDetail() {
    const [paneHeights, setPaneHeights] = useState([50, 50]);
    const containerRef = useRef(null); // Create a ref for the container

    const onDrag = (e, index) => {
      const newHeights = [...paneHeights];

      // Get the bounding rectangle of the container using the ref
      const containerRect = containerRef.current.getBoundingClientRect();

      // Calculate mouse position relative to the container's top
      const mousePositionPercent = ((e.clientY - containerRect.top) / containerRect.height) * 100;

      if (index === 0) {
        newHeights[0] = mousePositionPercent;
        newHeights[1] = 100 - mousePositionPercent;
      }

      setPaneHeights(newHeights);
    };
    
    return (
        <Box className="company-detail-container" ref={containerRef}> {/* Attach the ref here */}
          <Box height={`${paneHeights[0]}%`}>
            <Metric />
          </Box>
          <FlexDivider onDrag={onDrag} index={0} direction="horizontal" />
          <Box height={`${paneHeights[1]}%`}>
              {/* START PLACEHOLDER */}
              <Box height={`${paneHeights[1]}%`}>
              <h3 style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', margin: 0 }}>
                Source Document Here
              </h3>
            </Box>
            {/* END PLACEHOLDER */}
          </Box>
        </Box>
    );
}

export default CompanyDetail;
