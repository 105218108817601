import React, { useEffect, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { listDynKeTranscriptTranscriptionBlocks } from "../../../graphql_ap/queries";
import './TranscriptionBlocks.css'
import SingleTranscriptionBlock from './SingleTranscriptionBlock';

const TranscriptionBlocks = (props) => {
    const [transcriptionBlocks, setTranscriptionBlocks] = useState([]);

    useEffect(() => {
        async function fetchData() {
            if (props.ke_transcript_id) {
                try {
                    const transcriptionBlockData = await API.graphql(
                        graphqlOperation(listDynKeTranscriptTranscriptionBlocks, {
                            ke_transcript_id: props.ke_transcript_id,
                        })
                    );
                    const blocks = transcriptionBlockData.data.listDynKeTranscriptTranscriptionBlocks || [];
                    setTranscriptionBlocks(blocks.map(item => ({
                        ...item,
                        id: item.transcription_block_num
                    })));
                } catch (error) {
                    console.error("Error fetching transcription blocks:", error);
                }
            }
        }

        fetchData();
        
    }, [props.ke_transcript_id]); // Only re-run the effect if ke_transcript_id changes

    return (
        <div className='transcription-blocks-main'>
            {transcriptionBlocks && transcriptionBlocks.length > 0 ? (
                transcriptionBlocks.map((transcriptionBlock) => (
                    <div key={transcriptionBlock.id}>
                        <b>{transcriptionBlock.speaker_name}, {transcriptionBlock.speaker_title}:</b>
                    <SingleTranscriptionBlock transcription_block_id = {transcriptionBlock.transcription_block_id} utterance_num_list={props.utterance_num_list}/>
                    </div>
                ))
            ) : (
                <div></div>
                /* No transcription blocks found */
            )}
        </div>
    );
};

export default TranscriptionBlocks;
