import React from 'react';
import { Typography } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { format } from 'date-fns';

function PrivacyText() {
  const currentYear = new Date().getFullYear(); 
  return (
      <div>
      <Typography variant="body2" paragraph>
      Websites operated by Kessler Energy, LLC (collectively "Websites"), 
      including but not limited to the domains kesslerenergy.com and alpha-pilot.ai, 
      and all content available therein (including "Products" or "Trial 
      Products") are the exclusive property of Kessler Energy, LLC and/or its 
      affiliates (collectively "KE" or "Kessler"). Individuals accessing the 
      sites on behalf of a Client that has executed a KE "Master Agreement" (referred 
      to as "Authorized Users") are subject to that Master Agreement and, by accessing 
      the Websites, agree to be bound by the current "Terms of 
      Use" (available <RouterLink to="/terms">here</RouterLink>) 
      and "Privacy Policy" (this document). All other users ("Other Users") by 
      accessing the Websites agree to be bound by the current Terms of 
      Use (available <RouterLink to="/terms">here</RouterLink>), Privacy 
      Policy (this document) and KE's "Standard Master Agreement" 
      (available <a href="https://agreement.kessler.energy" target="_blank" rel="noopener noreferrer">here</a>). Authorized 
      Users and Other Users are referred to herein 
      collectively as "Users". The Terms of Use, Privacy Policy and Standard Master 
      Agreement may be amended or restated from time to time at KE's sole discretion 
      and are made available on the Websites to authenticated users. Users 
      indicate their acceptance of revised or amended terms by continuing to 
      access the Websites after the amended or restated version of the Terms 
      of Use, Privacy Policy and/or Standard Master Agreement have 
      been posted to the Websites.
      </Typography>
      <Typography variant="body2" paragraph>
      The terms "Website(s)", "Product(s)", "Trial Product(s)" and "Authorized User(s)" 
      are as defined in the corresponding Master Agreement or Standard Master Agreement.
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
      Client Privacy Policy
      </Typography>
      <Typography variant="body2" paragraph>
      Kessler Energy, LLC has adopted this policy to set forth the principles we will 
      follow in safeguarding the nonpublic personal information of our clients.  
      The relationship between Kessler Energy, LLC and our clients is the most important 
      asset of our firm. We strive to maintain our client's trust and confidence in our 
      firm, an essential aspect of which is our commitment to protect our clients' personal 
      information to the best of our ability. To these ends:<br /><br />
      1) We believe that all of our clients value their privacy so we shall not disclose their 
      personal information to anyone unless it is required by law, at their direction, or 
      necessary to provide them with our services.<br /><br />
      2) We shall not sell our client's personal information to anyone.<br /><br />
      3) We shall maintain strict physical and procedural safeguards to protect our clients' 
      personal information.<br /><br />
      4) We shall treat information about former clients in the same manner we treat 
      information about current clients.<br /><br />
      5) We shall restrict access to information about our clients to our employees and agents 
      who need it to perform their job duties.<br /><br />
      6) We shall provide an initial privacy notice to a new client at the time that our Form 
      ADV Part 2 information is initially provided to a new client.  If we obtain nonpublic 
      personal information about a person who does not become a client, we shall provide a privacy 
      notice to such consumer prior to disclosing such information to a nonaffiliated third party.<br /><br />
      7) The Compliance Officer shall regularly determine and verify compliance with this policy.
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
      Information Collected
      </Typography>
      <Typography variant="body2" paragraph>
      All information transmitted, printed or otherwise submitted to KE via the Websites shall 
      be deemed to be the property of KE and KE shall be free to use such information for any lawful 
      purpose as detailed herein. By using the Websites, Users consent to the collection 
      and use of this information.
      </Typography>
      <Typography variant="body2" paragraph>
      KE may automatically collect and/or track the following:<br /><br />
      1) Web page http headers (home server domain names, IP address, type of client computer, 
      client operating system, screen resolutions, color capabilities, browser plug-ins, language 
      settings, cookie preferences, search engine keywords, JavaScript enablement, user input to 
      our Websites (browser navigation data) and type of Web browser, URL visited just prior to 
      "Websites" and/or URL visited just after "Websites"); <br /><br />
      2) Information knowingly provided by Users through on-line forms, registration forms, 
      surveys and or other entries; <br /><br />
      3) Information, user specific or aggregate, on what pages Users access. <br /><br />
      4) Other information obtained through the course of doing business with Users about 
      themselves, their employers or parent organizations, affiliates or employees. 
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
      Credit Card Information
      </Typography>
      <Typography variant="body2" paragraph>
      KE may, in certain instances, collect credit card numbers and related information 
      from Users. When the credit card information is submitted to KE, such information is 
      encrypted and is protected with SSL encryption software. KE will use the credit card 
      information for the purpose of processing and completing fees in accordance with the 
      applicable Agreement and/or orders submitted by Users to the Websites. Credit card 
      information will be disclosed to third parties only as necessary to complete the 
      purchase transaction.
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
      Personal Information
      </Typography>
      <Typography variant="body2" paragraph>
      KE may collect, though the Websites or otherwise, certain personal information 
      about Customers, including, but not limited to, names, email addresses, 
      mailing addresses, and phone and fax numbers.
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
      Use of Cookies
      </Typography>
      <Typography variant="body2" paragraph>
      KE may currently or in the future use cookies and/or other new and evolving 
      technological sources of information. "Cookies" are a feature within browser 
      software. If enabled, KE may write cookies that may store small amounts of data 
      on the Users computer(s) about their visit to any of the Websites. Cookies assist 
      in tracking which information was entered and/or viewed on past visits. KE may use 
      cookies to: keep track of the number of return visits to the Websites; accumulate 
      and report detailed or aggregate, statistical information on website usage; deliver 
      specific content based on interests or past viewing history and store information 
      used to ensure accurate data is extracted from our systems. Cookies used by KE are 
      1st party cookies (cookie data cannot be shared across websites) and do not include 
      any personal data. Users may disable cookies, although the Websites 
      may not function properly.
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
      Use of Information Collected
      </Typography>
      <Typography variant="body2" paragraph>
      KE uses information collected as described above to monitor and improve product 
      quality and user experience. KE does not share personal information with other 
      companies or individuals outside of KE or its affiliates.
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
      Electronic Communications
      </Typography>
      <Typography variant="body2" paragraph>
      When Users access the Websites or send emails to KE, they are communicating 
      electronically and consent to receive communications from KE electronically.
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
      Retained Correspondence
      </Typography>
      <Typography variant="body2" paragraph>
      User acknowledges and accepts that any communication between the User, Kessler 
      and their respective agents, including but not limited to telephone 
      conversations, emails, instant chat, and text messages may be documented, 
      recorded and retained by Kessler indefinitely for internal quality control 
      and compliance purposes. Kessler is under no obligation to notify the User at 
      the time the communication is being recorded. Kessler may make information 
      pertaining to communications with Users available to government authorities 
      and/or industry regulators if required to do so legally or by industry regulation.
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
      Security
      </Typography>
      <Typography variant="body2" paragraph>
      KE maintains procedural, technical and physical safeguards to help protect 
      your information against loss, misuse, or unauthorized access, disclosure, 
      alteration or destruction.  However, no security system is impenetrable and 
      we make no guarantees, warranties or representations that your information 
      is protected from viruses and other security attacks.
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
      Service Providers
      </Typography>
      <Typography variant="body2" paragraph>
      KE sometimes engages third parties, such as contractors, to provide certain 
      operational services to us or on our behalf.  KE may share your information 
      with those third parties as necessary for the third party to provide that service.
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
      Complying with Legal Process
      </Typography>
      <Typography variant="body2" paragraph>
      KE will use or disclose User information in response to subpoenas, court orders, 
      warrants, or legal process or to otherwise establish or exercise KE's legal 
      rights or defend against legal claims or in the event that Users violate or 
      breach an agreement with KE. KE will use and disclose User information if KE 
      believes it is necessary to share information in order to investigate, prevent, 
      or take action regarding illegal activities, suspected fraud, situations 
      involving potential threats to the physical safety of any person, violations 
      of the Terms of Use listed here or any agreement between KE and Users, or as 
      otherwise required by law when responding to subpoenas, court orders 
      and other legal processes.
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
      Links
      </Typography>
      <Typography variant="body2" paragraph>
      The Websites and email messages from KE sometimes contain hypertext links 
      to the websites of third parties. KE is not responsible for the privacy 
      practices or the content of such other websites. Links to other websites 
      are provided for convenience and reference only. KE does not operate or 
      control in any respect any information, software, products or services 
      available on such third party websites. This Privacy Policy does not apply 
      to websites that are not linked to this policy.  Any information you 
      provide when you visit websites other than those covered by this policy 
      will be subject to the privacy policies posted on those sites. KE recommends 
      that you review the privacy policy at each such site to determine how 
      that site protects your privacy. The inclusion of a link to a website 
      does not imply any endorsement of the services or the site, its 
      contents, or its sponsoring organization.
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
      Affiliations / Merger / Sale
      </Typography>
      <Typography variant="body2" paragraph>
      KE further reserves the right to disclose, transfer or share personal 
      User information to companies who are affiliated with KE at KE's 
      sole discretion. If KE changes ownership through an acquisition, 
      merger, sale or other change of business status, it reserves the 
      right to transfer or assign the right to use personal User 
      information and any information submitted to the Websites 
      by Users in connection with any such corporate transaction.
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
      Foreign Use of Websites
      </Typography>
      <Typography variant="body2" paragraph>
      To the extent that Users access the Websites while domiciled 
      outside of the United States, Users acknowledge that any 
      information submitted to or otherwise obtained by Websites 
      is collected, processed and stored in the United States, 
      where the laws regarding the processing of your personal 
      information may be less stringent than the laws in your 
      country. By using this website you consent to the 
      collection, processing and storage of any such 
      information in the United States.
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
      Fraud Prevention
      </Typography>
      <Typography variant="body2" paragraph>
      If false or inaccurate information is provided, including in 
      relation to a person's identity and fraud is identified, 
      details about this information, including personal data, will 
      be passed to fraud prevention agencies. Law enforcement 
      agencies may access and use this information. KE and other 
      organizations may also access and use this information to 
      prevent fraud and money laundering, for example when: checking 
      details on applications for credit and credit related and 
      other facilities; managing credit and credit related accounts 
      or other facilities; recovering debt; checking details on 
      proposals and claims for all types of insurance; and 
      checking details of job applications and employees.
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
      Children's Privacy
      </Typography>
      <Typography variant="body2" paragraph>
      KE is strongly committed to the safety and protection of 
      children. KE, through the Websites, does not knowingly 
      accept or collect personal information (such as name, 
      address, e-mail, address, telephone number, social 
      security number, credit card information, date of birth, 
      zip code) from children under the age of 18.
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
      Right to Contact User
      </Typography>
      <Typography variant="body2" paragraph>
      KE reserves the right to contact Users regarding account 
      status and changes to agreements, Terms of Use, Privacy 
      Policy or any other policies or agreements 
      relevant to the User.
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
      General
      </Typography>
      <Typography variant="body2" paragraph>
      In the event of conflict between this Privacy Policy and a Master 
      Agreement or Standard Master Agreement, the Master Agreement or 
      Standard Master Agreement shall govern for the item(s) in conflict.
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
      Access, Update or Delete Your Information
      </Typography>
      <Typography variant="body2" paragraph>
      You can see, review and change the information we have about you 
      by sending us an email 
      at <a href="mailto:support@kesslerenergy.com">support@kesslerenergy.com</a>. At 
      any time, you may request us to delete your information by sending us an 
      email at <a href="mailto:support@kesslerenergy.com">support@kesslerenergy.com</a>.  Although 
      we will make every effort to delete your information from our 
      electronic databases, we may need to retain your information in our 
      archives and records to comply with law, resolve disputes, troubleshoot 
      problems, assist with any investigations, enforce our Terms of Use 
      and other policies, take other actions otherwise permitted by law, and 
      avoid incurring significant costs that would be required to remove 
      all information about you from all of our archived electronic records. In 
      addition, if you have provided information to us in paper or we 
      have recorded your information on paper, we may not be able to 
      delete all of that information from our paper records 
      or electronic copies of paper records.
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
      Contact Information
      </Typography>
      <Typography variant="body2" paragraph>
      Questions about these Terms of Use, Privacy Policy, the practices of the 
      Websites or other matters relating to KE may be directed, in 
      writing only, to:
      <br /><br />
      Kessler Energy, LLC<br />
      1415 South Voss, Suite 110-421<br />
      Houston, TX 77057<br />
      </Typography>
      <Typography variant="body2" paragraph>
      BY VISITING THE "Websites" AND/OR REQUESTING INFORMATION, PRODUCTS OR 
      SERVICES, "Users" AGREE TO BE BOUND BY THE "Terms of Use" AND "Privacy 
      Policy" LISTED ON THE "Websites". IF YOU DO NOT AGREE WITH THE "Terms 
      of Use" AND "Privacy Policy", PLEASE DO NOT PROVIDE ANY INFORMATION 
      OR USE ANY OF THE SERVICES OR PRODUCTS OFFERED OR PROVIDED ON 
      ANY OF THE WEBSITES REFERRED TO HEREIN.
      </Typography>
      <Typography variant="body2" paragraph>
      PLEASE NOTE: THE "Terms of Use" AND "Privacy Policy" REFERENCED HERE 
      CHANGE FROM TIME TO TIME AND CHANGES ARE EFFECTIVE UPON POSTING. PLEASE 
      CHECK BACK FREQUENTLY FOR UPDATES AS IT IS THE SOLE RESPONSIBILITY OF 
      "Users" TO BE AWARE OF CHANGES. "KE" DOES NOT PROVIDE NOTICES OF 
      CHANGES IN ANY MANNER OTHER THAN BY POSTING THE CHANGES ON THIS WEB SITE.
      </Typography>
      <Typography variant="body2" paragraph>
      © {currentYear} Kessler Energy
      </Typography>
    </div>
  )
}

export default PrivacyText;