import React, { useState } from 'react';
import { Box, Typography, IconButton, useTheme } from '@mui/material';
import { Star, StarBorder } from '@mui/icons-material';
import "./CompanyHeader.css";

function CompanyHeader({ companyName, primaryTicker, primaryExchange }) {
    const [isFavorite, setIsFavorite] = useState(false);

    const handleFavoriteClick = () => {
        setIsFavorite(!isFavorite);
    };

    return (
        <Box className="company-header-container">
            <Box display="flex" alignItems="center">
                <Box width="40px" /> 
                <Typography variant="h5" className="company-name">
                    {companyName}
                </Typography>
                <IconButton onClick={handleFavoriteClick} aria-label="favorite">
                    {isFavorite ? <Star color="primary" /> : <StarBorder color="primary" />}
                </IconButton>
            </Box>
            <Typography variant="body2" className="company-exchange">
                {primaryExchange}: {primaryTicker}
            </Typography>
        </Box>
    );
}

export default CompanyHeader;
