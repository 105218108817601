export const listCompanies = /* GraphQL */ `
  query ListCompanies {
    listCompanies {
    company_id
    company_name
    primary_exchange
    primary_ticker
    __typename
  }
}
`;

export const listCompanyFavorites = /* GraphQL */ `
  query ListCompanyFavorites {
    listCompanyFavorites {
    company_id
    __typename
  }
}
`;

export const listDynKeTranscripts = /* GraphQL */ `
  query ListDynKeTranscripts {
    listDynKeTranscripts {
      ke_transcript_id
      ticker
      call_utc
      call_title
      is_earnings_call
      ai_summary
      __typename
    }
  }
`;

export const listDynAnalytics = /* GraphQL */ `
  query ListDynAnalytics {
    listDynAnalytics {
      id
      name
      description
      __typename
    }
  }
`;

export const listDynKeTranscriptUtterances = /* GraphQL */ `
  query ListDynKeTranscriptUtterances($transcription_block_id: String!) {
    listDynKeTranscriptUtterances(
      transcription_block_id: $transcription_block_id
    ) {
      transcription_block_id
      utterance_num
      utterance_id
      ke_transcript_id
      text
      __typename
    }
  }
`;

export const listDynKeTranscriptTranscriptionBlocks = /* GraphQL */ `
  query ListDynKeTranscriptTranscriptionBlocks($ke_transcript_id: String!) {
    listDynKeTranscriptTranscriptionBlocks(
      ke_transcript_id: $ke_transcript_id
    ) {
      ke_transcript_id
      transcription_block_num
      transcription_block_id
      speaker_name
      speaker_title
      __typename
    }
  }
`;

export const listDynKeTranscriptAiGuidanceTable = /* GraphQL */ `
  query ListDynKeTranscriptAiGuidanceTable($ke_transcript_id: String!) {
    listDynKeTranscriptAiGuidanceTable(ke_transcript_id: $ke_transcript_id) {
      ke_transcript_id
      guidance_item_num
      guidance_item_id
      period_string
      item_title
      item_amount_string
      utterance_num_list
      __typename
    }
  }
`;

export const listDynKeTranscriptPreparedRemarksAiBullets = /* GraphQL */ `
  query ListDynKeTranscriptPreparedRemarksAiBullets(
    $prepared_remarks_id: String!
  ) {
    listDynKeTranscriptPreparedRemarksAiBullets(
      prepared_remarks_id: $prepared_remarks_id
    ) {
      prepared_remarks_id
      prepared_remarks_bullet_num
      prepared_remarks_bullet_id
      ke_transcript_id
      ai_bullet_text
      utterance_num_list
      is_guidance
      period_string
      __typename
    }
  }
`;

export const listDynKeTranscriptPreparedRemarksBlocks = /* GraphQL */ `
  query ListDynKeTranscriptPreparedRemarksBlocks($ke_transcript_id: String!) {
    listDynKeTranscriptPreparedRemarksBlocks(
      ke_transcript_id: $ke_transcript_id
    ) {
      ke_transcript_id
      prepared_remarks_block_num
      prepared_remarks_block_id
      speaker_name
      speaker_title
      ai_summary
      __typename
    }
  }
`;

export const listDynKeTranscriptQuestionAnswerAiBlockBullets = /* GraphQL */ `
  query ListDynKeTranscriptQuestionAnswerAiBlockBullets(
    $question_answer_ai_block_id: String!
  ) {
    listDynKeTranscriptQuestionAnswerAiBlockBullets(
      question_answer_ai_block_id: $question_answer_ai_block_id
    ) {
      question_answer_ai_block_id
      question_answer_ai_block_bullet_num
      question_answer_ai_block_bullet_id
      ke_transcript_id
      ai_question_text
      ai_answer_list
      utterance_num_list
      __typename
    }
  }
`;

export const listDynKeTranscriptQuestionAnswerAiBlocks = /* GraphQL */ `
  query ListDynKeTranscriptQuestionAnswerAiBlocks($ke_transcript_id: String!) {
    listDynKeTranscriptQuestionAnswerAiBlocks(
      ke_transcript_id: $ke_transcript_id
    ) {
      ke_transcript_id
      question_answer_ai_block_num
      question_answer_ai_block_id
      question_answer_block_title
      __typename
    }
  }
`;

export const listCompanyMetrics = /* GraphQL */ `
query ListCompanyMetrics($companyId: ID!) {
  listCompanyMetrics(company_id: $companyId) {
    company_metric_id
    company_metric_name
    is_monetary
    non_monetary_units
    company {
      company_name
      primary_ticker
      primary_exchange
    }
  }
}
`;