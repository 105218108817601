import React, { useEffect, useState, useRef } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { listDynKeTranscriptUtterances } from "../../../graphql_ap/queries";
import "./SingleTranscriptionBlock.css";

const SingleTranscriptionBlock = ({ transcription_block_id, utterance_num_list }) => {
  const [utterances, setUtterances] = useState([]);
  const highlightRef = useRef(null);

  useEffect(() => {
    async function fetchData() {
      if (transcription_block_id) {
        try {
          const utteranceData = await API.graphql(
            graphqlOperation(listDynKeTranscriptUtterances, {
              transcription_block_id: transcription_block_id,
            })
          );
          const utts = utteranceData.data.listDynKeTranscriptUtterances || [];
          setUtterances(utts.map(item => ({
            ...item,
            id: item.utterance_num,
          })));
        } catch (error) {
          console.error("Error fetching utterances:", error);
        }
      }
    }
    fetchData();
  }, [transcription_block_id]);

  useEffect(() => {
    if (utterance_num_list && utterance_num_list.length > 0 && highlightRef.current) {
      highlightRef.current.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
      // Clear the ref after scrolling
      highlightRef.current = null;
    }
  }, [utterance_num_list]);

  return (
    <div>
      {utterances.map((utterance) => {
        const isHighlighted = utterance_num_list.includes(utterance.id);
        return (
          <p
            key={utterance.id}
            ref={isHighlighted ? highlightRef : null}
            className={isHighlighted ? 'highlighted' : ''}
          >
            {utterance.text}
          </p>
        );
      })}
      {utterances.length === 0 && <p>No utterances found.</p>}
    </div>
  );
};

export default SingleTranscriptionBlock;
