import React, { useState, useEffect }  from 'react';
import { Box, Button, Grid, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './LandingPage.css'
import videoSrc from './../../assets/images/nyc_flyover_slow.mp4';
import zIndex from '@mui/material/styles/zIndex';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import { styled } from '@mui/system';

const CardStyled = styled(Card)({
  width: '300px',
  height: '250px',
  minHeight:'250px',
  top: '40%',
  position: 'relative',
  padding: '20px',
  boxSizing: 'border-box',
  margin: 'auto',
  transition: '0.3s',
  '&:hover': {
    backgroundColor: '#ecf0f1', 
    transform: 'scale(1.05)',
  },
});




const cards = [
  {
    title: 'Data Extraction',
    text: 'Add structure and uniformity to otherwise disparate information.',
  },
  {
    title: 'Financial Modeling',
    text: 'Evaluation and prediction of key financial indicators.',
  },
  {
    title: 'Event Synthesis',
    text: 'Transform news flow into succinct and timely insights.',
  },
  {
    title: 'Investment Selection',
    text: 'Initiate real-time proactive investment decisions.',
  },
];

function LandingPage({ onSignInClick, onSignUpClick }) {
  const theme = useTheme();
  const navigate = useNavigate();
  const [fontSize, setFontSize] = useState('60px');
  const [fontSizeContactsLarge, setfontSizeContactsLarge] = useState('25px');
  const [fontSizeContactsMedium, setfontSizeContactsMedium] = useState('20px');
  const [fontSizeContactsSmall, setfontSizeContactsSmall] = useState('15px');
  const [isPhone, setIsPhone] = useState(window.innerWidth <= 600);
  const [isTooNarrowForCards, setIsTooNarrowForCards] = useState(window.innerWidth <= 1000);

  useEffect(() => {
    const handleResize = () => {
      setIsPhone(window.innerWidth <= 600);
      setIsTooNarrowForCards(window.innerWidth <= 1000);
    };
  
    window.addEventListener('resize', handleResize);
  
    // Cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const updateFontSize = () => {
      if (window.innerWidth < 700) {
        setFontSize('8vw');
        setfontSizeContactsLarge('4vw');
        setfontSizeContactsMedium('3.3vw');
        setfontSizeContactsSmall('2.5vw');
      } else {
        setFontSize('60px');
        setfontSizeContactsLarge('25px');
        setfontSizeContactsMedium('20px');
        setfontSizeContactsSmall('15px');
      }
    };

    window.addEventListener('resize', updateFontSize);
    updateFontSize();

    return () => window.removeEventListener('resize', updateFontSize);
  }, []);

useEffect(() => {
  const hash = window.location.hash;
  if (hash) {
    const section = document.getElementById(hash.substring(1));
    if (section) {
      section.scrollIntoView({ behavior: 'smooth' });
    }
  }
}, [window.location.hash]);


const ScrollableGrid = styled(Grid)({
  overflowX: 'auto',
  overflowY: 'hidden',
  scrollbarWidth: 'none',
  msOverflowStyle: 'none',
  '&::-webkit-scrollbar': {
    display: 'none'
  },
  flexWrap: 'nowrap', // Prevent cards from stacking vertically
  justifyContent: isTooNarrowForCards ? 'flex-start' : 'center',
  padding: '20px 20px 20px 20px', // Add buffer at the left and right'
});

  const signInButtonStyle = {
    backgroundColor: theme.palette.ke.medium_blue, // Use medium_blue for button
    '&:hover': {
      backgroundColor: theme.palette.ke.dark_blue // Darker blue on hover
    }
  };

  const signUpButtonStyle = {
    backgroundColor: 'white', // Use medium_blue for button
    zIndex: '1',
    height: '50px',
    width: '200px',
    color: theme.palette.ke.dark_blue,
    '&:hover': {
      backgroundColor: theme.palette.ke.medium_blue, // Darker blue on hover
      color: 'white'
    }
  };

  return (
    <Box style={{
        border:'none',
        position: 'relative',
        overflow: 'auto',
        }}> 
   <Box style={{
    border:'none',
    height: 'calc(100vh - 62px - 24px - 2px)',
    minHeight: '450px',
    position: 'relative',
    overflow: 'hidden',
  }}>
    <Box style={{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      display: 'flex', // Layout the children in a column
      flexDirection: 'column', // Stack the children vertically
      alignItems: 'center', // Center the children horizontally
      gap: '20px', // Add space between the children
    }}>
      <h1 style={{
        color:'white', 
        fontSize: fontSize,
        fontWeight: '300',
        fontFamily: 'Alata',
        whiteSpace: 'nowrap',
      }}>AI meets Wall Street</h1>
      <Button variant="contained" sx={signUpButtonStyle} onClick={() => navigate('/signup')}>
        Register
      </Button>
    </Box>
          {isPhone ? (
    <Box style={{
      backgroundColor: theme.palette.ke.dark_blue,
      width: '100%',
      height: '100%',
      position: 'absolute',
      zIndex: '-1',
      top: '0',
      left: '0'
    }} />
  ) : (
          <video autoPlay loop muted playsInline style={{ width: 'auto', height: 'auto', top: '0', left: '0', zIndex: '-1', position: 'absolute'}}>
        <source src={videoSrc} type="video/mp4" />
      </video>
      )}
      </Box>
      <Box style={{
        position: 'relative', 
        backgroundColor:'#3498db', 
        height: 'calc(62vh - 62px - 24px - 2px)',
        minHeight: '450px',
        }}>
        <h1 id='description' style={{
          color:'white', 
          position: 'absolute', 
          zIndex: '1',
          top: '40%',
          left: '50%',
          transform: 'translate(-50%, -50%)', // Offset by its own dimensions to center exactly
          fontSize: fontSize,
          fontWeight: '300',
          fontFamily: 'Alata',
          width: '70%',
          textAlign: 'center',
        }}>alpha-pilot.ai executes work previously performed by specialized financial analysts.</h1>
      </Box>
      <Box id='cards' style={{
        position: 'relative', 
        backgroundColor:'white', 
        height: 'calc(72vh - 62px - 24px - 2px)',
        minHeight: '450px',
        display: 'flex', // to layout cards in a row
        justifyContent: 'center', // space-around
        flexWrap: 'nowrap',
        }}>
        <h1 id='institutional' style={{
          color:theme.palette.ke.dark_blue, 
          position: 'absolute', 
          zIndex: '1',
          top: '15%',
          left: '50%',
          transform: 'translate(-50%, -50%)', // Offset by its own dimensions to center exactly
          fontSize: fontSize,
          fontWeight: '300',
          fontFamily: 'Alata',
          width: '70%',
          textAlign: 'center',
        }}>AI for institutional investors</h1>
      <ScrollableGrid container spacing={2}>
      {cards.map((card, i) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={i}>
      <CardStyled key={i}>
        <CardHeader title={card.title} />
        <CardContent>
          {card.text}
        </CardContent>
      </CardStyled>
      </Grid>
    ))}
    </ScrollableGrid>
      </Box>
      <Box id='contact-us' style={{
        position: 'relative', 
        backgroundColor: '#ecf0f1', 
        display: 'flex',
        justifyContent: 'space-around',
        flexDirection: 'column',
        alignItems: 'center',
        }}>
        <h2 id='contact-us-header' style={{
          fontSize: fontSizeContactsLarge,
          fontWeight: '300',
          fontFamily: 'Alata',
          width: '70%',
          textAlign: 'center',
        }}>CONTACT US</h2>
        <a href="tel:713-225-6400" style={{ textDecoration: 'none', color: 'inherit'}}>
  <p id='phone' style={{fontSize: fontSizeContactsMedium}}>713-225-6400</p>
</a>
<Box style={{
    display: 'flex', // Add this to layout the boxes in a row
    justifyContent: 'center', 
    width: '100%', // Add this to make the boxes take up the full width
    boxSizing: 'border-box',
  }}>
    <Box id='mailing-address' style={{
      //padding: '0 30px 30px 50px',
      padding: '0 3vw 3vw 5vw',
      boxSizing: 'border-box',
    }}>
      <h3 style={{fontSize: fontSizeContactsMedium}}>MAILING ADDRESS</h3>
      <p style={{fontSize: fontSizeContactsSmall}}>1415 S Voss Rd, Ste 110-421</p>
      <p style={{fontSize: fontSizeContactsSmall}}>Houston, TX 77057</p>
    </Box>
    <Box id='corporate-office' style={{
      //padding: '0px 20px 30px 30px',
      padding: '0 2vw 3vw 3vw',
      boxSizing: 'border-box',
    }}>
      <h3 style={{fontSize: fontSizeContactsMedium}}>CORPORATE OFFICE</h3>
      <p style={{fontSize: fontSizeContactsSmall}}>108 Wild Basin Rd. South, Ste 250</p>
      <p style={{fontSize: fontSizeContactsSmall}}>Austin, TX 78746</p>
    </Box>
  </Box>
      </Box>
    </Box>
  );
}

export default LandingPage;
