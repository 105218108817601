import React, { useState, useRef } from 'react';
import { Box } from '@mui/material';
import FlexDivider from "../../../Miscellaneous/FlexDivider";
import CompanyEventDocumentButtons from "./CompanyEventDocumentButtons";
import CompanyEventMetricList from "./CompanyEventMetricList";
import AnalysisDetail from './AnalysisDetail/AnalysisDetail';
import "./CompanyEventAnalysis.css";

function CompanyEventAnalysis() {
    const [paneHeights, setPaneHeights] = useState([20,25,55]); // Start with 3 equal sections
    const containerRef = useRef(null); // Create a ref for the container

    const onDrag = (e, index) => {
      const newHeights = [...paneHeights];

      // Get the bounding rectangle of the container using the ref
      const containerRect = containerRef.current.getBoundingClientRect();

      // Calculate mouse position relative to the container's top
      const mousePositionPercent = ((e.clientY - containerRect.top) / containerRect.height) * 100;

      if (index === 0) {
        // Adjust heights for the first divider
        newHeights[0] = mousePositionPercent;
        newHeights[1] = 100 - mousePositionPercent - paneHeights[2];
      } else if (index === 1) {
        // Adjust heights for the second divider
        newHeights[1] = mousePositionPercent - paneHeights[0];
        newHeights[2] = 100 - mousePositionPercent;
      }

      setPaneHeights(newHeights);
    };
    
    return (
        <Box className="company-event-analysis-container" ref={containerRef} sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
          <Box sx={{ height: `${paneHeights[0]}%`, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
            <Box sx={{ flexGrow: 1, overflow: 'auto' }}>
              {/* START PLACEHOLDER */}
              <Box sx={{ height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <h3 style={{ margin: 0 }}>AI Summary Here</h3>
              </Box>
              {/* END PLACEHOLDER */}
            </Box>
            <Box sx={{ flexShrink: 0 }}>
              <CompanyEventDocumentButtons />
            </Box>
          </Box>
          <FlexDivider onDrag={onDrag} index={0} direction="horizontal" />
          <Box sx={{ height: `${paneHeights[1]}%`, overflow: 'auto' }}>
            <CompanyEventMetricList />
          </Box>
          <FlexDivider onDrag={onDrag} index={1} direction="horizontal" />
          <Box sx={{ height: `${paneHeights[2]}%`, overflow: 'auto' }}>
            <AnalysisDetail />
          </Box>
        </Box>
    );
}

export default CompanyEventAnalysis;
