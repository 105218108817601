import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Box } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';


const StyledRouterLink = styled(RouterLink)(({ theme }) => ({
  color: 'inherit',
  textDecoration: 'none',
  marginLeft: theme.spacing(2),
  fontSize: '0.8rem',
  display: 'flex',
  alignItems: 'center',
  '&:hover': {
    color: theme.palette.ke.link_hover,
  },
}));

const StyledLink = styled('a')(({ theme }) => ({
  color: 'inherit',
  textDecoration: 'none',
  marginLeft: theme.spacing(2),
  fontSize: '0.8rem',
  display: 'flex',
  alignItems: 'center',
  '&:hover': {
    color: theme.palette.ke.link_hover,
  },
}));

const StyledText = styled('span')(({ theme }) => ({
  color: 'inherit',
  marginLeft: theme.spacing(2),
  fontSize: '0.8rem',
  display: 'flex',
  alignItems: 'center',
}));

const Footer = () => {

  const [isScreenSmall, setIsScreenSmall] = useState(window.innerWidth < 600);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsScreenSmall(window.innerWidth < 700);
    };

    window.addEventListener('resize', checkScreenSize);

    // Cleanup function
    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, []);

  return (
    <AppBar position="static" color="primary" sx={(theme) => ({ background: theme.palette.ke.dark_blue, top: 'auto', bottom: 0 })}>
      <Toolbar sx={{ 
          minHeight: '24px !important', 
          paddingY: '1px', 
          display: 'flex', 
          alignItems: 'center',
          overflowX: 'auto',
          whiteSpace: 'nowrap',
          scrollbarWidth: 'none', // For Firefox
          '&::-webkit-scrollbar': { // For Chrome, Safari and Opera
            display: 'none'
          }
          }}>
        <StyledRouterLink to="/landing#contact-us-header">Contact</StyledRouterLink>
        <Box sx={{ flexGrow: 1 }} />
        {!isScreenSmall && (
          <StyledText>
            © {new Date().getFullYear()} Kessler Energy, LLC or its affiliates.
          </StyledText>
        )}
        <StyledRouterLink to="/terms">Terms</StyledRouterLink>
        <StyledRouterLink to="/disclaimers">Disclaimers</StyledRouterLink>
        <StyledLink href="https://adv2a.kessler.energy" target="_blank" rel="noopener noreferrer">ADV2a</StyledLink>
        <StyledLink href="https://adv2b.kessler.energy" target="_blank" rel="noopener noreferrer">ADV2b</StyledLink>
        <StyledRouterLink to="/privacy">Privacy</StyledRouterLink>
      </Toolbar>
    </AppBar>
  );
};

export default Footer;
