// ForgotPassword.js

import React, { useState } from 'react';
import { Auth } from 'aws-amplify';
import {
  Container,
  CssBaseline,
  Box,
  Avatar,
  Typography,
  TextField,
  Button, 
  Grid,
  Link,
  Alert, 
  useTheme
} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import { useNavigate } from 'react-router-dom';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const theme = useTheme();

  const sendCodeButtonStyle = {
    backgroundColor: theme.palette.ke.medium_blue, // Use medium_blue for button
    mt: 3, 
    mb: 2,
    '&:hover': {
      backgroundColor: theme.palette.ke.dark_blue // Darker blue on hover
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setError('');
    try {
      await Auth.forgotPassword(email);
      navigate('/resetpassword', { state: { email } }); 
    } catch (error) {
      console.error('Error submitting forgot password request:', error);
      setError(error.message || 'An unexpected error occurred.');
      if (error.code === 'UserNotFoundException') {
        setError('Email not found. Please try again.'); 
      }
      if (error.code === 'InvalidParameterException') {
        Auth.resendSignUp(email)
          .then(() => {
            console.log('Confirmation code resent successfully');
            navigate("/verification");
          })
          .catch(resendError => {
            console.error('Error while resending confirmation code: ', resendError);
          });
      }
    }
  };

  return (
    <Box className="main-component-container"  sx={(theme) => ({ background: theme.palette.ke.background, height: "100%"})}>
    <Container component="main" maxWidth="xs">
      <Box sx={{
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}>
        <Avatar sx={{ m: 1, bgcolor: theme.palette.ke.medium_grey}}>
          <EmailIcon />
        </Avatar>
        <Typography component="h1" variant="h5">
          Forgot Password
        </Typography>

        {error && (
          <Alert severity="error" sx={{ width: '100%', mt: 2 }}>
            {error}
          </Alert>
        )}
        <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
          <TextField
            margin="normal"
            required
            fullWidth
            id="email"
            label="Email Address"
            name="email"
            autoComplete="email"
            autoFocus
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={sendCodeButtonStyle}
          >
            Send Code
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="#" variant="body2" onClick={() => navigate('/signin')}>
                Return to Sign In
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
    </Box>
  );
}

export default ForgotPassword;
