import React, { useEffect, useState } from "react";
import { API, graphqlOperation } from "aws-amplify";
import { listDynKeTranscriptAiGuidanceTable } from "../../../graphql_ap/queries";
import { DataGrid, GridToolbarContainer, GridToolbarExport, useGridApiRef } from "@mui/x-data-grid";
import './GuidanceTable.css'

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

const maxWidths = {
  period_string: 300,
  item_title: 300,
  item_amount_string: 300
};

const calculateWidth = (text = '', maxWidth) => {
  const approxCharWidth = 10; // This is a rough estimate and might need adjustment.
  const width = text.length * approxCharWidth;
  return Math.min(width, maxWidth);
};

const GuidanceTable = (props) => {
  const [guidanceTable, setGuidanceTable] = useState([]);
  const [selectionModel, setSelectionModel] = useState([]);
  const [columnWidths, setColumnWidths] = useState({
    period_string: 100,
    item_title: 100,
    item_amount_string: 100
  });
  const [totalTableWidth, setTotalTableWidth] = useState(0);

  useEffect(() => {
    async function fetchData() {
      if (props.ke_transcript_id) {
        try {
          const guidanceData = await API.graphql(
            graphqlOperation(listDynKeTranscriptAiGuidanceTable, {
              ke_transcript_id: props.ke_transcript_id,
            })
          );
          const guidanceList = guidanceData.data.listDynKeTranscriptAiGuidanceTable;
          const newColumnWidths = { ...columnWidths };

          // Calculate the width for each item
          guidanceList.forEach(item => {
            newColumnWidths.period_string = Math.max(newColumnWidths.period_string, calculateWidth(item.period_string, maxWidths.period_string));
            newColumnWidths.item_title = Math.max(newColumnWidths.item_title, calculateWidth(item.item_title, maxWidths.item_title));
            newColumnWidths.item_amount_string = Math.max(newColumnWidths.item_amount_string, calculateWidth(item.item_amount_string, maxWidths.item_amount_string));
          });

          // Update state
          setColumnWidths(newColumnWidths);

          setGuidanceTable(guidanceList.map(item => ({
            ...item,
            id: item.guidance_item_num
          })));

          // Calculate and update the total width
          const totalWidth = Object.values(columnWidths).reduce((sum, width) => sum + width + 1, 0);
          setTotalTableWidth(totalWidth);

        } catch (error) {
          console.error("Error fetching guidance table:", error);
        }
      }
    }

    fetchData();
  }, [props.ke_transcript_id, columnWidths]);

  // Update your columns definition
  const columns = [
    { field: "period_string", headerName: "Period", width: columnWidths.period_string },
    { field: "item_title", headerName: "Item", width: columnWidths.item_title },
    { field: "item_amount_string", headerName: "Amount", width: columnWidths.item_amount_string },
  ];

  // Render the DataGrid only if there are records to display
  const hasRecords = guidanceTable.length > 0;

  const rowClickHandler= (params) => {
    let utterance_num_list = params.row.utterance_num_list;
    props.onUtteranceNumListSelect(utterance_num_list);
    const itemId = `GuidanceTable-${params.id}`;
    props.onSelectItem(itemId);
  }

  const apiRef = useGridApiRef();

  const handleClearSelection = () => {
    if (apiRef.current) {
      apiRef.current.setRowSelectionModel([]);
    }
  };
  
  useEffect(() => {
    if (guidanceTable.length > 0 && props.selectedItemId && !props.selectedItemId.startsWith("GuidanceTable-")) {
      handleClearSelection();
    }
  }, [props.selectedItemId, guidanceTable.length]);
  

  return (
    <div>
      {hasRecords ? (
        <div className="pane-content">
        <div className="guidance-table-card">
          <div><b>GUIDANCE:</b></div>
          <br></br>
          <DataGrid 
            apiRef={apiRef} 
            className="searchDataGrid" 
            rows={guidanceTable} 
            columns={columns} 
            hideFooter 
            rowHeight={30}
            onRowClick={rowClickHandler}
            selectionModel={selectionModel}
            onSelectionModelChange={(newSelection) => setSelectionModel(newSelection)}
            getRowClassName={(params) => {
              return `GuidanceTable-${params.id}` === props.selectedItemId ? 'selected-row' : '';
          }}
            components={{
              Toolbar: CustomToolbar,
            }}
            sx={{
              '& .MuiDataGrid-toolbarContainer': {
                minHeight: '25px !important', 
                height: '25px !important',
                //background: '#1976d2',
              },
              '& .MuiDataGrid-columnHeaders': {
                minHeight: '30px !important', 
                height: '30px !important',
                //color: 'white',
                //background: '#1976d2',
              },
              '& .MuiDataGrid-cell:focus': {
                outline: 'none',
              },
            }}
          />
        </div>
      </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default GuidanceTable;
